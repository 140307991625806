import React,{useEffect} from "react"
import { Tabs } from 'antd';
import Container from "./Container";
import { useSelector } from "react-redux";
import MembersPage from "../../../dashBourd/Members/Members.page";
import StudentPage from "../../../dashBourd/Students/Student.page";

const { TabPane } = Tabs;

const Demo = ({match}) =>{
 
return (
  <Container >
  <Tabs defaultActiveKey="1"  centered style={{direction : "ltr"}}>
  <TabPane tab="المسؤولين" key="3">
    <MembersPage  admin={true}/>
    </TabPane>
    <TabPane tab="الطلبة" key="2">
      <StudentPage />
    </TabPane>
    <TabPane tab="اعضاء هيئة التدريس" key="1">
      <MembersPage />
    </TabPane>
  </Tabs>
  </Container>
)};

export default Demo