import React, { useState } from "react";
import { Modal, Button } from "antd";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { UpdateGraduationYear } from "../../../actions/gradutionsYear.action";
import { setAlert } from "../../../actions/alert";

const UpdateGraduationYearForm = ({
  UpdateGraduationYear,
  correntGraduationYear,
  setAlert,
  names,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [GraduationYear, setGraduationYear] = useState({
    _id: correntGraduationYear._id,
    title: correntGraduationYear.title,
  });

  const { title } = GraduationYear;

  const onChange = (e) => {
    setGraduationYear({ ...GraduationYear, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    UpdateGraduationYear(correntGraduationYear._id, title);
    setAlert(`تم التعديل علي بيانات سنة التخرج ${names} `, "success");

    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>تعديل علي البيانات</a>
      <Modal
        title="تعديل علي البيانات "
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            تعديل
          </Button>,
        ]}
      >
        <FormInput
          type="text"
          name="title"
          value={title}
          onChange={onChange}
          label="العنوان"
        />
      </Modal>
    </>
  );
};

export default connect(null, {
  UpdateGraduationYear,
  setAlert,
})(UpdateGraduationYearForm);
