import React, { Fragment, useEffect } from "react";
import "./Subjects.styles.scss";
import { connect } from "react-redux";
// import SubjectItems from "../Subjects/SubjectItems";
import { getOneMember } from "../../actions/Members.actions";
import { getSubjects } from "../../actions/Subjects.action";
import Spinner from "../../components/Layout/Spinner";

const Home = ({
  match,
  subjects: { subjects, loading },
  getOneMember,
  getSubjects,
  member,
}) => {
  useEffect(() => {
    getOneMember(match.params.id);
    getSubjects();
    // eslint-disable-next-line
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        <div className="card text-center">
          <h1> المواد الدراسية</h1>
        </div>

        {subjects &&
          subjects.map(
            (subject) =>
              subject.SubjectAuthor &&
              member &&
              subject.SubjectAuthor.id == member._id && (
                // <SubjectItems subject={subject} />
                <h1></h1>
              )
          )}
      </div>
    </Fragment>
  );
};

const mapStatetoProps = (state) => ({
  subjects: state.subjects,
  member: state.members.member,
});
export default connect(mapStatetoProps, { getSubjects, getOneMember })(Home);
