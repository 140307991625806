import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addCLass } from "../../../actions/Class.action";
import { setAlert } from "../../../actions/alert";

const AddClass = ({ addCLass, setAlert ,schoolid ,studyYear }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  

  const [adv, setAdv] = useState({
    NumberOfClass: "",
    letterOfClass: "",
    school : schoolid
  });
  
  

  const { NumberOfClass, letterOfClass, school } = adv;

  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (NumberOfClass === "" ) {
      setAlert("ارجو تعبئة كل الحقول     ", "danger");
    } else {
      console.log(adv)
      addCLass( NumberOfClass, letterOfClass ,school ,studyYear);
      setAdv({
        NumberOfClass: "",
        letterOfClass: "",
        school : schoolid
      });
      setAlert("تمت إضافة المجموعة بنجاح", "success");
      setIsModalVisible(false);    }
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
           تكوين مجموعة 
      </Button>
      <Modal title="تكوين مجموعة" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          إضافة
        </Button>,
      ]}
      >
      <form >

            <FormInput
              name="NumberOfClass"
              type="text"
              handleChange={onChange}
              value={NumberOfClass}
              label="عنوان المجموعة"
              required
            />
              <FormInput
              name="letterOfClass"
              type="text"
              handleChange={onChange}
              value={letterOfClass}
              label="حرف المجموعة"
              required
            />
          </form>
      </Modal>
    </>
  );
};

export default connect(null, {
  addCLass,
  setAlert,
})(AddClass);
