import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Progress } from 'antd';

const Alert = ({ progress }) =>
  progress &&
  progress.percent > 0 && (
    
    <Progress type="circle" percent={progress.percent} />
  );

Alert.propTypes = {
  progress: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  progress: state.progress,
});

export default connect(mapStateToProps)(Alert);
