import React from "react";
import { connect } from "react-redux";
import { deleteSubject } from "../../../actions/Subjects.action";
import { setAlert } from "../../../actions/alert";

import {DeleteOutlined} from  "@ant-design/icons"
import { Popconfirm, message } from 'antd';

const DeleteSubject = ({ deleteSubject, id, setAlert }) => {
  function confirm(e) {
    deleteSubject(id);
    message.success('تم الحذف');
  }
  
  function cancel(e) {
    console.log(e);
    message.error('تم إلغاء العملية');
  }


  return (
    <Popconfirm
    title="هل انت متأكد من ذلك"
    onConfirm={confirm}
    onCancel={cancel}
    okText="نعم"
    cancelText="لا"
  >
    {/* eslint-disable-next-line */}
    <a  href="#"><DeleteOutlined /></a>
  </Popconfirm>

  );
};

export default connect(null, {
  deleteSubject,
  setAlert,
})(DeleteSubject);
