import {
  GET_GRADUTIONYEARS,
  ADD_GRADUTIONYEAR,
  UPDATE_GRADUTIONYEAR,
  DELETE_GRADUTIONYEAR,
  ERROR_GRADUTIONYEAR,
} from "../actions/types";

const inialState = {
  graduationYears: [],
  graduationYear: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GRADUTIONYEARS:
      return {
        ...state,
        graduationYears: payload,
        loading: false,
      };
    case ADD_GRADUTIONYEAR:
      return {
        ...state,
        graduationYears: [...state.graduationYears, payload],
        loading: false,
      };
    case UPDATE_GRADUTIONYEAR:
      return {
        ...state,
        graduationYears: state.graduationYears.map((graduation) =>
          graduation._id === payload._id ? payload : graduation
        ),
      };
    case DELETE_GRADUTIONYEAR:
      return {
        ...state,
        graduationYears: state.graduationYears.filter(
          (graduation) => graduation._id !== payload
        ),
      };
    case ERROR_GRADUTIONYEAR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
