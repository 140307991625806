import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { updateStudent } from "../../../actions/Student.action";
import { setAlert } from "../../../actions/alert";

const UpdateStudents = ({
  updateStudent,
  correntStudent,
  setAlert,
  names,
}) => {
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Student, setStudent] = useState({
    _id: correntStudent._id,
    nid: correntStudent.nid,
    name: correntStudent.name,
    brthday: correntStudent.brthday,
    sex: correntStudent.sex,
    nationality: correntStudent.nationality,
    adress: correntStudent.adress,
    email: correntStudent.email,
  });

  const {
    name,
    email,
    nid,
    brthday,
    sex,
    nationality,
    adress,
  } = Student;

  const onChange = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateStudent(Student);
    setAlert(`تم التعديل علي بيانات الطالب ${names} `, "success");
    setStudent({
      _id: correntStudent._id,
      nid: correntStudent.nid,
      name: correntStudent.name,
      brthday: correntStudent.brthday,
      sex: correntStudent.sex,
      nationality: correntStudent.nationality,
      adress: correntStudent.adress,
      email: correntStudent.email,
    });
    setIsModalVisible(false);
  };

  return (

   <>
       {/* eslint-disable-next-line */}
      <a  onClick={showModal}>
        تعديل علي البيانات 
      </a>
      <Modal title="تعديل علي البيانات " visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تعديل
        </Button>,
      ]}
      >
            <FormInput
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              label="إسم الطالب"
            />
            <FormInput
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              label="البريد الإلكتروني"
            />
            <FormInput
              type="nunber"
              name="nid"
              value={nid}
              onChange={onChange}
              label="الرقم الوطني"
            />
            <FormInput
              type="date"
              name="brthday"
              value={brthday}
              onChange={onChange}
              label="تاريخ الميلاد"
            />
            <label for="sex">اختر الجنس</label>
            <select
              type="text"
              name="sex"
              value={sex}
              onChange={onChange}
              label="الجنس"
              id="sex"
            >
              <option value={sex}>{sex}</option>

              <option value="ذكر">ذكر</option>
              <option value="انثي">انثي</option>
            </select>
            <FormInput
              type="text"
              name="nationality"
              value={nationality}
              onChange={onChange}
              label="الجنسية"
            />
            <FormInput
              type="text"
              name="adress"
              value={adress}
              onChange={onChange}
              label="عنوان السكن"
            />
      </Modal>
    </>
      
  );
};


export default connect(null, {
  updateStudent,
  setAlert,
})(UpdateStudents);
