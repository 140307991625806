import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {Link} from "react-router-dom"
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import { Col,Row,Button } from "antd";
import FormInput from "../form-input/form-input.component";

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    rolls: "",
  });

  const { name, email, password, password2, rolls } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      console.log("كلمة المرور غير متطابقة");
    } else {
      register({ name, email, password, rolls });
      console.log("تم عملية انشاء الحساب بنجاح");

      setFormData({
        name: "",
        email: "",
        password: "",
        password2: "",
        rolls: "",
      });
    }
  };

  // if (isAuthenticated) {
  //   return <Redirect to='/dashboard' />;
  // }

  return (
    <Fragment>
      <section id="regester">
      <Row gutter={16}>
          
          <Col className="gutter-row form" md={7} sm={24}>
          <h3 className="large text-center mt-2">التسجيل في النظام</h3>
          <form
            className="form"
            style={{ direction: "rtl" }}
          >
          <FormInput
          name="name"
          type="text"
          handleChange={(e) => onChange(e)}
          value={name}
          autocomplete="off"
          label="الاسم ثلاثي"
          required
          />
          <FormInput
          name="email"
          type="email"
          handleChange={(e) => onChange(e)}
          value={email}
          autocomplete="off"
          label="البريد الالكتروني"
          required
          />  
          <small className="form-text">
            الرجاء التأكد من صحة البريد الالكتروني
          </small>
          <FormInput
          name="password"
          type="password"
          handleChange={(e) => onChange(e)}
          value={password}
          autocomplete="off"
          label="كلمة المرور"
          required
          /> 
          <FormInput
          name="password2"
          type="password"
          handleChange={(e) => onChange(e)}
          value={password2}
          autocomplete="off"
          label="تأكيد كلمة المرور"
          required
          />
          <div class="input-field ">
            <label>صلاحيات المسؤولين</label>
            <select
              type="number"
              name="rolls"
              value={rolls}
              onChange={(e) => onChange(e)}
            >
              <option value="0" selected>
                التسجيل ك :  
              </option>
              <option value="1">معلم/ة</option>
              <option value="0">تلميذ/ة</option>
            </select>
          </div>
          <Button type="primary mt-2" block onClick={onSubmit} >  
              تسجيل  
          </Button>
          </form>
          <h4 className="mt-2">    املك حساب في النظام؟ <Link to="/login" > تسجيل دخول </Link></h4>
          </Col>
          <Col className="gutter-row side " md={15}>
              <div className="login-side"></div>
            </Col>
          </Row>
        </section>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register })(Register);
