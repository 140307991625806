import React, {useEffect} from 'react'
import Container from "./Container"
import {useSelector ,useDispatch} from "react-redux"
import {Empty} from  "antd"
import {getAdvs} from "../../../actions/advs.action"
const MainPage = ({match}) => {
    const {school} = useSelector(state => state.schools)
    const {year} = useSelector(state => state.years)
    const dispatch = useDispatch()
    useEffect(() => {
      year && school && dispatch(getAdvs( school._id ,year._id))
    }, [school])
    const { advs} = useSelector(state => state.advs)
    const contentStyle = {
        width: "70vw",
        color:"black",
        textAlign: "rigth",
        margin: "3rem auto",
      };
    return (
        <Container url={match.params.url} >
        {advs && advs.length > 0 ? 
         < >
         <h1 className="text-center"> لوحة الاعلانات</h1>
         {advs.map(adv =>
          <div style={contentStyle} key={adv._id}>
            <div 
            style={{background : `url(${adv &&adv.image}) center`,  height: "30vh",  borderRadius : "5px",marginBottom : "1rem"

          }}  
            />
            <h1 className="text-center" >{adv.title}</h1>
            <p dangerouslySetInnerHTML={{__html :adv.discription}}></p>
          </div>
           )}
         </>:
        <Empty />
         }
        
        </Container>
    )
}

export default MainPage
