import {
  GET_TSS,
  TSS_ERROR,
  ADD_TSS,
  UPDATE_TSS,
  REMOVE_ERRORS,
} from "../actions/types";

const initialState = {
  tss: {},
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TSS:
      return {
        ...state,
        tss: payload,
        loading: false,
      };

    case ADD_TSS:
      return {
        ...state,
        tss: [payload, ...state.subjects],
        loading: false,
      };
    case UPDATE_TSS:
      return {
        ...state,
        tss: state.tss.map((subject) =>
          subject._id === payload._id ? payload : subject
        ),
      };

    case TSS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case REMOVE_ERRORS:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
}
