import React, {  useState } from "react";
import { Modal, Button ,Menu } from 'antd';

import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { updateTopic } from "../../../actions/Topics.action";
import { setAlert } from "../../../actions/alert";
import {EditOutlined} from "@ant-design/icons"
  const UpdateTopic = ({ updateTopic, correntTopic, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Topic, setTopic] = useState({
    id: correntTopic._id,
    title: correntTopic.title, 
  });


  const { title } = Topic;

  const onChange = (e) => {
    setTopic({ ...Topic, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateTopic(Topic);
    setAlert("تم التعديل علي بيانات الصف", "success");
    setIsModalVisible(false);
  };

  return (
  <> 
    {/* eslint-disable-next-line */}
    <a  onClick={showModal}>
      <EditOutlined />   
    </a>
    <Modal title="التعديل على الموضوع" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        إلغاء الامر
      </Button>,
      <Button key="submit" type="primary"  onClick={onSubmit}>
        تعديل
      </Button>,
    ]}
    >
    <form onSubmit={onSubmit}>
    <FormInput
      name="title"
      type="text"
      handleChange={onChange}
      value={title}
      label="عنوان الموضوع"
      required
    />  
    </form>
    </Modal>
  </>

     
  );
};

export default connect(null, {
  updateTopic,
  setAlert,
})(UpdateTopic);
