import { combineReducers } from "redux";
import auth from "./auth";
import advs from "./advs.reduser";
import students from "./students.reduser";
import subjects from "./subjects.reduser";
import members from "./members.reduser";
import alert from "./alert";
import schools from "./schools.reduser";
import post from "./post";
import progress from "./Progress";
import tss from "./TssReducer";
import classes from "./classes.reduser";
import courses from "./course.reduser";
import topics from "./topics.reduser";
import lessons from "./lessons.reduser";
import lectures from "./lecturesReducers";
import events from "./events.reduser";
import exercises from "./exercise.reduser";
import homeworks from "./homeWorksReducers";
import years from "./studyYears.reduser";
import news from "./news.reduser";
import graduationYears from "./graduationYear.reduser";
export default combineReducers({
  auth,
  advs,
  alert,
  events,
  lectures,
  tss,
  years,
  exercises,
  graduationYears,
  news,
  topics,
  lessons,
  courses,
  schools,
  post,
  students,
  subjects,
  members,
  progress,
  classes,
  homeworks,
});
