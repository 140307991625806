import React, { Fragment, useState } from "react";
import { Modal, Button,message } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { deleteMember } from "../../../actions/Members.actions";
import { setAlert } from "../../../actions/alert";

const DeleteMember = ({ deleteMember, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DeleteC = () => {
    deleteMember(id);
    message.success('تم الحذف');
    setIsModalVisible(false);
  };
  return (
  
      <>
      {/*  eslint-disable-next-line */}
      <a  onClick={showModal}>
      حذف عضو هيئة التدريس
      </a>
      <Modal title="Basic Modal" visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              لا
            </Button>,
            <Button key="submit" type="primary"  onClick={DeleteC}>
              نعم
            </Button>,
          ]}>
        <p>  ؟ هل انت متأكد انك تريد حدف عضو هيئة التدريس</p>
      </Modal>
    </>
    
  );
};

export default connect(null, {
  deleteMember,
  setAlert,
})(DeleteMember);
