import React, {  useState } from "react";
import { Modal, Button, message } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addLesson } from "../../../actions/Lesson.actions";
import {BookOutlined} from  "@ant-design/icons"

const AddLesson = ({ addLesson ,topic }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  
  const [title , setTitle] = useState("") 
  
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" ) {
      message.error("ارجو ادخال عنوان الدرس")
    } else {
      addLesson( title , topic);
      setTitle("")
      message.success("تمت إضافة الدرس بنجاح ");
      setIsModalVisible(false);    
    }
  };
  return (
    <>
    {/* eslint-disable-next-line */}
      <a  onClick={showModal}>
        <BookOutlined />
      </a>
      <Modal title="إضافة درس" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          إضافة
        </Button>,
      ]}
      >
            <FormInput
              name="title"
              type="text"
              handleChange={(e) => setTitle(e.target.value)}
              value={title}
              label="عنوان الدرس"
              required
            />
      </Modal>
    </>
  );
};

export default connect(null, {
  addLesson,
})(AddLesson);
