import React, { Fragment } from "react";
import spinner from "./spinner.gif";
import { Skeleton } from 'antd';


export default () => (
  <Fragment>
    {/* <img
      src={spinner}
      style={{ width: "500px", margin: "auto", display: "block" }}
      alt="Loading..."
    /> */}
    <Skeleton  active/>
  </Fragment>
);
