import {
  GET_SUBJECTS,
  ERROR_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  GET_SUBJECT,
  ADD_SUBJECT,
  SUBJECT_FILTERED,
  SUBJECT_FILTEREDـBY_CLASS
} from "../actions/types";

const inialState = {
  subjects: [],
  subject: null,
  loading: true,
  filtered : [],
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBJECT:
      return {
        ...state,
        subject: payload,
        loading: false,
      };
      case ADD_SUBJECT:
      return {
        ...state,
        subjects: [payload, ...state.subjects],
        loading: false,
      };

    case GET_SUBJECTS:
      return {
        ...state,
        subjects: payload,
        loading: false,
      };
    case SUBJECT_FILTERED : 
      return {
        ...state,
        filtered: state.subjects.filter((subject) =>{
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            subject.klass.match(regex) ||
            subject.subjectname.match(regex) 
          );
        }),
      };
      case SUBJECT_FILTEREDـBY_CLASS : 
      return {
        ...state,
        filtered: state.subjects.filter((subject) =>{
          return (
            subject.klass ===  payload
          );
        }),
      };
    case UPDATE_SUBJECT:
      return {
        ...state,
        subjects: state.subjects.map((subject) =>
          subject._id === payload._id ? payload : subject
        ),
        loading: false,
      };
    case DELETE_SUBJECT:
      return {
        ...state,
        subjects: state.subjects.filter((subject) => subject._id !== payload),
        loading: false,
      };
    case ERROR_SUBJECT:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
