import React, { useEffect ,useState } from 'react'
import { useDispatch ,useSelector } from 'react-redux'
import {getfiles} from "../../actions/lectures.actions"
import {Image ,Row,Col , Button , List} from "antd"
import ReactPlayer from "react-player";
import {DownloadOutlined} from "@ant-design/icons"

const LessonItems = ({lesson}) => {
    const dispatch = useDispatch()
    useEffect(() =>{
       dispatch(getfiles(lesson._id))
    } , [])
    const [Status , setStatus] = useState("")

    const {files} = useSelector(state => state.lectures)
    const Images = files && files.filter(file => file.category === "image")
    const Videos = files && files.filter(file => file.category === "video")
    const Docs = files && files.filter(file => file.category === "doc")

    const selectStatus = (status) => {
        setStatus(status)
    }

    const data = []
   if(Images && Images.length > 0 ){
       data.push("صور")
   } 
   if(Videos && Videos.length > 0 ) {
     data.push("دروس مسجلة")
   } 
    if(Docs && Docs.length > 0 ) {
    data.push("ملفات")
   }

   if(lesson.description && lesson.description.length > 0 ) {
    data.push("النص")
   }
    
     
    return (
        <div >
         <Row gutter={16} style={{paddingTop : "2rem"}}>
           <Col span={6} >
                <List
                  size="small"
                  bordered
                  dataSource={data}
                  renderItem={item => <List.Item onClick={()=> selectStatus(item)}>
                      <h3>{item}</h3>
                      </List.Item>}
                />
           </Col>
           <Col span={17}>
           {Status === "صور" && 
            <> 
                { Images &&  Images.map(file => 
                        
                      <Image
                        width={200}
                        src={file.file}
                        style={{margin : "0.2rem"}}
                        /> 
                        )}
                </>
                }

       {Status === "ملفات" && 
            <> 
                { Docs &&  Docs.map(file =>
                <a href={file.file}  target="_blank">
                <Button type="primary" icon={<DownloadOutlined />} size="large">
                 تحميل الملف 
                </Button>
                </a>  
                        
                        )}
                </>
                }

                {Status === "دروس مسجلة" && 
                 <> 
                { Videos &&  Videos.map(file => 
                       <ReactPlayer  url={file.link} controls={true}  style={{margin : "0 auto"}}/>       
                )}
                </>
                }
                {Status === "النص" && 
                 <div dangerouslySetInnerHTML={{__html : lesson.description}}> 
                
                </div>
                }
           </Col>
         </Row>
         </div>
         
        
    )
}

export default LessonItems
