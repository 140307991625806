import Axios from "axios"
import {
GET_TOPICS_BY_COURSE,
ADD_TOPIC,
DELETE_TOPIC,
UPDATE_TOPIC,
ERROR_TOPIC,
GET_OLDTOPICS
} from "./types"

export const getTopicsByCourse = (id) => async dispatch =>{
    try {
        const {data} =await Axios.get(`/api/topics/course/${id}`)
        dispatch({
            type : GET_TOPICS_BY_COURSE,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ERROR_TOPIC,
            payload: { msg: error.response, status: error.response },
          });        
    }
}

export const getOldTopicsByOldCourse = (id) => async dispatch =>{
  try {
      const {data} =await Axios.get(`/api/topics/oldcourse/${id}`)
      dispatch({
          type : GET_OLDTOPICS,
          payload: data
      })
  } catch (error) {
      dispatch({
          type: ERROR_TOPIC,
          payload: { msg: error.response, status: error.response },
        });        
  }
}



export const addTopic = (Topic) => async dispatch =>{
    try {
        const {data} = await Axios.post('/api/topics' , {Topic})
        dispatch({
            type : ADD_TOPIC,
            payload : data
        })
        const res =await Axios.get(`/api/topics/course/${Topic.course}`)
        dispatch({
            type : GET_TOPICS_BY_COURSE,
            payload: res.data
        })
    } catch (error) {
        dispatch({
            type : ERROR_TOPIC,
            payload : {msg :error.response&&  error.response.msg , status :error.response&& error.response.status}
        })        
    }
}

export const importFromOtherCourse = (courseID , oldCourseID) => async dispatch =>{
  try {
      const {data} = await Axios.post('/api/topics/import' , {courseID , oldCourseID})
      dispatch({
          type : GET_TOPICS_BY_COURSE,
          payload : data
      })
  } catch (error) {
      dispatch({
          type : ERROR_TOPIC,
          payload : {msg :error.response&&  error.response.msg , status :error.response&& error.response.status}
      })        
  }
}
export const importTopicFromOtherCourse = (courseID , oldTopic) => async dispatch =>{
  try {
      const {data} = await Axios.post('/api/topics/importtopic' , {courseID , oldTopic})
      dispatch({
          type : GET_TOPICS_BY_COURSE,
          payload : data
      })
  } catch (error) {
      dispatch({
          type : ERROR_TOPIC,
          payload : {msg :error.response&&  error.response.msg , status :error.response&& error.response.status}
      })        
  }
}



export const deleteTopic = (id) => async (dispatch) => {
    try {
      // eslint-disable-next-line
      const res = await Axios.delete(`/api/topics/${id}`);
      dispatch({
        type: DELETE_TOPIC,
        payload: id,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_TOPIC,
        payload: {
          msg: error.response&&error.response.statusText,
          status: error.response&&error.response.status,
        },
      });
    }
  };
  
  export const updateTopic = (formData) => async (dispatch) => {
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const res = await Axios.put(`/api/topics/${formData.id}`, formData, config);
      dispatch({
        type: UPDATE_TOPIC,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_TOPIC,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };