import axios from "axios";
import moment from "moment"
import {
  GET_EVENTS,
  ERROR_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  GET_EVENT,
  ADD_EVENT,
} from "./types";


// get Events by Teacher 
export const getEventsByTeacher = (_id) => async (dispatch) => {
  try {
    const {data} = await axios.get(`/api/events/teacher/${_id}`);

    for (let i = 0; i < data.length; i++) {
      for (let i = 0; i < data.length; i++) {
        data[i].start =    moment.utc(data[i].start).toDate();
        data[i].end = moment.utc(data[i].end).toDate();
        
      }
    }
    dispatch({
      type: GET_EVENTS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EVENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// get Events by Teacher 
export const getEventsByClass = (_id) => async (dispatch) => {
  try {
    const {data} = await axios.get(`/api/events/class/${_id}`);

    for (let i = 0; i < data.length; i++) {
      for (let i = 0; i < data.length; i++) {
        data[i].start =    moment.utc(data[i].start).toDate();
        data[i].end = moment.utc(data[i].end).toDate();
        
      }
    }
    dispatch({
      type: GET_EVENTS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EVENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET CONFERANCES
export const getEvents = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/events");
    dispatch({
      type: GET_EVENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EVENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// Get One Event
export const getEvent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/events/${id}`);
    dispatch({
      type: GET_EVENT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_EVENT,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Add Course
export const addEvent = (event) => async (
  dispatch
) => {
  try {
    const {data} = await axios.post("/api/events", event);
    data.start = moment.utc(data.start).toDate();
    data.end = moment.utc(data.end).toDate();
    dispatch({
      type: ADD_EVENT,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_EVENT,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// UPDATE COURSE
export const updateEvent = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const {data} = await axios.put(
      `/api/events/${formData.id}`,
      formData,
      config
    );
    data.start = moment.utc(data.start).toDate();
    data.end = moment.utc(data.end).toDate();
    dispatch({
      type: UPDATE_EVENT,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EVENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// DELETE COURSE
export const deleteEvent = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/events/${id}`);
    dispatch({
      type: DELETE_EVENT,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EVENT,
      payload: {
        msg: error.response&&error.response.statusText,
        status: error.response&&error.response.status,
      },
    });
  }
};

///////////////////////////////////////////////////////


// export const getCoursesbyTeacherId = () => async (dispatch) => {
//   try {
//     const res = await axios.get("/api/courses/teacher");
//     dispatch({
//       type: GET_COURSES,
//       payload: res.data,
//     });
//   } catch (error) {
//     console.log(error);
//     dispatch({
//       type: ERROR_EVENT,
//       payload: { msg: error.response, status: error.response },
//     });
//   }
// };


