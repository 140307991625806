import React , { useEffect } from 'react'
import {  Spin ,Divider,PageHeader} from 'antd';
import {useDispatch ,useSelector} from "react-redux";
import {getExercise} from "../../actions/Exercise.actions"
import {getExerciseHomeWorks , getExerciseHomeWorksByStudent} from "../../actions/homeWorks.actions"
import Addfile from '../../components/modals/ExerciseFiles/Addfile';
import AddfileLink from '../../components/modals/ExerciseFiles/AddfileLink';
import ExerciseItems from "./ExerciseItems"
import AddText from "../../components/modals/Exercise/addText"


import AddHomeWorkDoc from '../../components/modals/HomeWork/AddHomeWork';
import AddHomeWorkAnswers from '../../components/modals/HomeWork/AddHomeWorkAnswers';
import  AddHomeWorkImages from '../../components/modals/HomeWork/AddHomeWorkImages';
import HomeWorksItems from './HomeWorksItems';
import "./exercise.style.css"
import {ArrowRightOutlined} from "@ant-design/icons"

const LessonPage = ({match}) => {
  const dispatch = useDispatch() 
  const { user } = useSelector(state => state.auth)

  useEffect(()=>{
    dispatch(getExercise(match.params.id))
    user.Student && dispatch(getExerciseHomeWorksByStudent(match.params.id))
    user.Teacher && dispatch(getExerciseHomeWorks(match.params.id))
    // eslint-disable-next-line
   },[])
   const { exercise , loading } = useSelector(state => state.exercises)
   const {homeworks} = useSelector(state => state.homeworks) 


   return !exercise ?  <div className="center">
   <Spin  size="large" />
 </div>  : (
        <div className="container bg-page">
        
       <div>
         <Divider orientation="left">
         <PageHeader
          className="site-page-header"
          onBack={() => window.history.back()}
          title="عناصر التمرين"
          backIcon ={<ArrowRightOutlined />}
        />
         </Divider>
         <ExerciseItems  exercise={exercise}/>
         <br />
         {homeworks && homeworks.length > 0 &&
         <table className="striped container-fluid ">
            <thead>
              <tr>
                <th className="right-align">الاسم</th>
                <th className="right-align">اسم المادة</th>
                <th className="right-align"> حل التمرين</th>
                <th className="right-align"></th>
              </tr>
            </thead>
            <tbody>
             { homeworks.map((h) => ( <HomeWorksItems  homework={h}/>))}             
            </tbody>
          </table>
         }
       
        {exercise && exercise.teacher === user._id  && <div style={{ position: 'fixed', bottom: "10px" ,left : "40vw"}}>
        <AddText correntExercise={exercise} />
        <Addfile exerciseID={exercise &&exercise._id}  correntCategory='doc' />
        <AddfileLink exerciseID={exercise && exercise._id} />
        <Addfile exerciseID={exercise && exercise._id}  correntCategory='image' />
        </div>}

        {user && user.Student && !homeworks.length > 0   && 
        <div style={{ position: 'fixed', bottom: "10px" ,left : "40vw"}}>
        <AddHomeWorkImages exerciseID={exercise &&exercise._id}  />
        <AddHomeWorkDoc exerciseID={exercise &&exercise._id}  />
        <AddHomeWorkAnswers exerciseID={exercise &&exercise._id}  />
        </div>}
        
      </div>
      </div>
    )
}

export default LessonPage
