import React , {useState ,useEffect} from 'react'
import Alert from "../../../components/Layout/Alert";
import {connect } from "react-redux"
import Progress from "../../../components/Layout/Progress";
import NavBar from "../../../components/Layout/Nav";
import { Menu, Layout,Avatar ,Image} from 'antd';
import {useSelector ,useDispatch} from "react-redux"
import {getSchoolByUrl} from "../../../actions/school.action"
import {getActiveStudyYear} from "../../../actions/stydyYear.action"
import {
  LoginOutlined,HomeOutlined,MenuUnfoldOutlined,
  MenuFoldOutlined,
  BookOutlined,
  CalendarOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom"
import { logout } from '../../../actions/auth';

const { Header,  Content  } = Layout;

const Container = (props) => {
    const dispatch =  useDispatch()
    const school = useSelector(state=>state.schools.school)
    const user = useSelector(state => state.auth.user)
    useEffect(()=>{
      !school && dispatch(getSchoolByUrl(props.url))
      !school && dispatch(getActiveStudyYear(props.url))

    },[])
    const[value , setValue] = useState({
        collapsed: true,
      })
    
      const toggle = () => {
      setValue({
          collapsed: !value.collapsed,
        });
      };

    return (
        <Layout style={{height:"100vh"}} >
        <NavBar value={value}>
          <>
        <div className="logo my-2" >
         {user && user.image ?  <Avatar
          size={64}
              src={<Image src={user.image} />}
          /> : <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large" >
          {user.name[0]}
        </Avatar> }
        

         
          </div>
          
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            
          <Menu.Item key="1" icon={<HomeOutlined  style={{ fontSize:"1.2rem" }}/>}>
            <Link to={`/teacher/${props.url ?props.url : school.Url }`} >الرئيسية</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<CalendarOutlined style={{ fontSize:"1.2rem" }}/>}>
               <Link to={`/teacher/${props.url ?props.url : school.Url}/table`} >
          الجدول
           </Link>
           </Menu.Item>

            <Menu.Item key="2" icon={<BookOutlined   style={{ fontSize:"1.2rem" }}/>}>
              <Link to={`/teacher/${props.url ?props.url : school.Url }/courses`} >المواد</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<ArrowLeftOutlined  style={{ fontSize:"1.2rem" }}/>}>
        <Link to={`/teacher`} >
          الرئيسية
        </Link>
      </Menu.Item> 
            <Menu.Item key="7" icon={<LoginOutlined  style={{ fontSize:"1.2rem" }}/>}
         onClick={()=>props.logout()}>
           تسجيل خروج
            </Menu.Item>
          </Menu>
          </>
        </NavBar>

          <Layout className="site-layout"
         
          >
            <Header className="site-layout-background" style={{ paddingLeft: "2px" }}>
              {React.createElement(value.collapsed ?   MenuFoldOutlined : MenuUnfoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: '0 3px',
                padding: 24,
                maxHeight: "90vh",
                overflow:"scroll"
              }}
            >
              <div className="container">
            <Alert />
            <Progress />
          </div>
                 {props.children}
          </Content>
        </Layout>
      </Layout>
    )
}

export default connect(null , {logout})(Container)
