import axios from "axios";
import {
  GET_LESSONS,
  ERROR_LESSON,
  UPDATE_LESSON,
  DELETE_LESSON,
  GET_LESSON,
  ADD_LESSON,
  CLEAR_LESSON,
  GET_EXAMS,
  GET_FILES,
  GET_POSTS,
  GET_POST, 
  UPDATE_TOPIC,
  GET_TOPICS_BY_COURSE,
} from "./types";


// get Lessons by Topic Id 
export const getLessonsByTopic = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lessons/topic/${_id}`);
    dispatch({
      type: GET_LESSONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET Lessons
export const getLessons = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/lessons");
    dispatch({
      type: GET_LESSONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// Get One Lesson
export const getLesson = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lessons/${id}`);
    dispatch({
      type: GET_LESSON,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Add Lesson
export const addLesson = (title ,topic) => async (
  dispatch
) => {
  try {
    const res = await axios.post("/api/lessons", {title ,topic});
    dispatch({
      type: UPDATE_TOPIC,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// UPDATE Lesson
export const updateLesson = (formData ,courseId) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/lessons/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_LESSON,
      payload: res.data,
    });
    const {data} =await axios.get(`/api/topics/course/${courseId}`)
    dispatch({
        type : GET_TOPICS_BY_COURSE,
        payload: data
    })
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_LESSON,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// DELETE COURSE
export const deleteLesson = (id , courseId) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/lessons/${id}`);
    dispatch({
      type: UPDATE_TOPIC,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_LESSON,
      payload: {
        msg: error.response&&error.response.statusText,
        status: error.response&&error.response.status,
      },
    });
  }
};

export const importLessonFromOtherTopic = (topicID,oldLessonID) => async dispatch =>{
  try {
      const {data} = await axios.post('/api/lessons/importlesson' , {topicID,oldLessonID})
      dispatch({
          type : UPDATE_TOPIC,
          payload : data
      })
  } catch (error) {
      dispatch({
          type : ERROR_LESSON,
          payload : {msg :error.response&&  error.response.msg , status :error.response&& error.response.status}
      })        
  }
}

///////////////////////////////////////////////////////


export const clearLesson = () => (dispatch) => {
  dispatch({
    type: CLEAR_LESSON,
  });
};


// Get All Files Subject
export const getfiles = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lessons/lectures/${id}`);
    dispatch({
      type: GET_FILES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get All Exams for this Subject
export const getExams = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lessons/exams/${id}`);
    dispatch({
      type: GET_EXAMS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get posts
export const getPosts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lessons/posts/${id}`);

    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get One post
export const getPost = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lessons/post/${id}`);

    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_LESSON,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};




