import React, { useState } from "react";
import { Modal, Button,Menu } from 'antd';
import { connect } from "react-redux";
import { deleteCLass } from "../../../actions/Class.action";
import { setAlert } from "../../../actions/alert";

const DeleteCLass = ({ deleteCLass, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DeleteC = () => {
    deleteCLass(id);
    setAlert("تم حذف الصف بنجاح ", "success");
    setIsModalVisible(false);
  };
  return (
  

  <>
     <a onClick={showModal} danger>
        حذف الفصل
      </a>
    <Modal title="Basic Modal" visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          الغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={DeleteC}>
        تأكيد
      </Button>,
      ]}
    >
      <p>؟ هل انت متأكد انك تريد حذف هدا الصف</p>
    </Modal>
  </>
  );
};

export default connect(null, {
  deleteCLass,
  setAlert,
})(DeleteCLass);
