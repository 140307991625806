import React, {  useState } from "react";
import { connect  } from "react-redux";
import { addAdminToSchool } from "../../../actions/Members.actions";
import { Modal, message ,Button} from 'antd';


const AddAdminToSchool = ({ addAdminToSchool, id ,schoolID }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const AddAdmin = () => {
    addAdminToSchool(id ,schoolID );
    message.success("تم إضافته   كمسؤول");
    setIsModalVisible(false);
  };
  return (

<>{/*  eslint-disable-next-line */}
      <a  onClick={showModal}>
      إضافته كمسؤول
      </a>
      <Modal title="تأكيد الطلب " visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={AddAdmin}>
              نعم
            </Button>,
          ]}>
        <p>هل انت متأكد من إضافته  كمسؤول </p>

      </Modal>
    </>
      
     
  );
};

export default connect(null, {
  addAdminToSchool,
})(AddAdminToSchool);
