import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import { connect } from "react-redux";
import { deleteStudent } from "../../../actions/Student.action";
import { setAlert } from "../../../actions/alert";

const DeleteStudent = ({ deleteStudent, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DeleteC = () => {
    deleteStudent(id);
    setAlert("تم حذف الطالب ", "success");
    setIsModalVisible(false);
  };
  return (
      <>
      {/*  eslint-disable-next-line */}
      <a  onClick={showModal}>
        حذف الطالب 
      </a>
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
              إلغاء الامر
        </Button>,
        <Button key="submit" type="danger"  onClick={DeleteC}>
              حذف
        </Button>,
      ]}>
        <p>؟ هل انت متأكد انك تريد حدف هدا الطالب</p>
      </Modal>
    </>
  );
};

export default connect(null, {
  deleteStudent,
  setAlert,
})(DeleteStudent);
