import React from "react";

import { connect } from "react-redux";
import { deleteAdv } from "../../../actions/advs.action";
import {DeleteOutlined } from "@ant-design/icons"
import { Popconfirm, message } from 'antd';

const DeleteAdv = ({ deleteAdv, id }) => {
  function confirm(e) {
    deleteAdv(id);
    message.success('تم الحذف');
  }
  
  function cancel(e) {
    console.log(e);
    message.error('تم إلغاء العملية');
  }


  return (
    <Popconfirm
    title="هل انت متأكد من ذلك"
    onConfirm={confirm}
    onCancel={cancel}
    okText="نعم"
    cancelText="لا"
  >
    {/* eslint-disable-next-line */}
    <a className="lesson-config" href="#"><DeleteOutlined /></a>
  </Popconfirm>

  );
};
export default connect(null, {
  deleteAdv,
})(DeleteAdv);
