import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Divider, List, Pagination } from "antd";
import { useSelector, useDispatch } from "react-redux";
import AddSubject from "../../components/modals/subjects/AddSubject";
import { subjectFilter, getSubjects } from "../../actions/Subjects.action";
import SubjectItems from "./SubjectItems";
import SubjectsFilter from "./SubjectsFilter";

const SubjectsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { subjects, filtered } = useSelector((state) => state.subjects);
  const { school } = useSelector((state) => state.schools);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(6);

  useEffect(() => {
    dispatch(getSubjects(school._id));
    // eslint-disable-next-line
  }, []);
  const data = [
    "الكل",
    "السنة الاولى",
    "السنة الثانية",
    "السنة الثالثة",
    "السنة الرابعة",
  ];
  const onShowSizeChange = (current = 1, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    console.log(current);
  };

  const onChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return (
    <div className="container-fluid" style={{ direction: "rtl" }}>
      <Divider orientation="left">
        <h1>
          {" "}
          المواد الدراسية
          {user.departments &&
            user.departments.length > 0 &&
            user.departments.map(
              (s) =>
                s.id._id.toString() === school._id.toString() &&
                s.roll === "admin" && (
                  <>
                    <AddSubject department={school._id} />
                  </>
                )
            )}
        </h1>
      </Divider>

      <Row gutter={16}>
        <Col md={4} sm={10} xs={10} className="hide-sm">
          <List
            size="small"
            header={<div>فلتر </div>}
            bordered
            dataSource={data}
            renderItem={(item, i) => (
              <List.Item
                onClick={() => dispatch(subjectFilter(`${i}`))}
                style={{ cursor: "pointer" }}
              >
                {item}
              </List.Item>
            )}
          />
        </Col>
        <Col md={20} sm={14}>
          <Row gutter={16}>
            <Col lg={22} md={24} sm={24} xs={24}>
              <SubjectsFilter />
            </Col>
            {filtered && filtered.length > 0 ? (
              filtered.map((subject) => (
                <Fragment key={subject._id}>
                  <Col lg={7} md={12} sm={24} xs={22}>
                    <SubjectItems subject={subject} />
                  </Col>
                </Fragment>
              ))
            ) : (
              <>
                {subjects &&
                  subjects
                    .slice((currentPage - 1) * PageSize, currentPage * PageSize)
                    .map((subject) => (
                      <Fragment key={subject._id}>
                        <Col lg={7} md={12} sm={24} xs={22}>
                          <SubjectItems subject={subject} />
                        </Col>
                      </Fragment>
                    ))}
              </>
            )}
          </Row>
        </Col>
      </Row>
      <div className="center" style={{ direction: "ltr" }}>
        <Pagination
          pageSizeOptions={[6, 12, 21, subjects.length]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={2}
          current={currentPage}
          defaultPageSize={6}
          onChange={onChange}
          total={subjects.length}
        />
      </div>
    </div>
  );
};
export default SubjectsPage;
