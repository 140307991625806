import React, { Fragment, useState } from "react";
import FormInput from "../../../../components/form-input-exam/form-input.component";
import "./Exams.style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditExam } from "../../../../actions/Exams.actions";
import { connect } from "react-redux";

import {
  faPlus,
  faMinusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect } from "react";

const NweTest = ({ exam, EditExam }) => {
  const [quiz, setQuiz] = useState({
    id: exam._id,
    Title: exam.Title,
    subTitle: exam.subTitle,
    subject: exam.subject,
    Questions: exam.Questions,
  });

  const [Questions, setQuestions] = useState(exam.Questions);

  const onChangeTitle = (e) =>
    setQuiz({ ...quiz, [e.target.name]: e.target.value });

  const handleQuestionsChange = (idx) => (evt) =>
    setQuestions(
      Questions.map((Q, sidx) =>
        idx !== sidx ? Q : { ...Q, quistion: evt.target.value }
      )
    );

  const handleAnswerChange = (qidx, ansidx) => (evt) => {
    setQuestions(
      Questions.map((Q, sidx) =>
        qidx !== sidx
          ? Q
          : {
              ...Q,
              answers: Q.answers.map((A, aidx) =>
                ansidx !== aidx ? A : { ...A, answer: evt.target.value }
              ),
            }
      )
    );
  };

  const handleAddQuistion = () => {
    setQuestions([...Questions, { quistion: "", answers: [{ answer: "" }] }]);
    console.log(Questions);
  };

  const handleChangeRadio = (qidx) => (e) => {
    console.log(e.target.value);
    setQuestions(
      Questions.map((Q, sidx) =>
        qidx !== sidx ? Q : { ...Q, correctAnswer: e.target.value }
      )
    );
  };

  const handleAddAnswer = (qidx) => () =>
    setQuestions(
      Questions.map((Q, sidx) =>
        qidx !== sidx ? Q : { ...Q, answers: [...Q.answers, { answer: "" }] }
      )
    );

  const handleRemoveQuistion = (qidx) => () => {
    setQuestions(Questions.filter((s, sidx) => qidx !== sidx));
    setQuiz({ ...quiz, Questions: Questions });
  };
  const handleRemoveAnswer = (qidx, ansidx) => () => {
    setQuestions(
      Questions.map((Q, sidx) =>
        qidx !== sidx
          ? Q
          : {
              ...Q,
              answers: Q.answers.filter((A, aidx) => ansidx !== aidx),
            }
      )
    );
  };
  useEffect(() => {
    setQuiz({ ...quiz, Questions: Questions });
    // eslint-disable-next-line
  }, [Questions]);

  const onSubmit = () => {
    EditExam(quiz);
  };
  return (
    exam && (
      <div className="container body">
        <div className="card text-center">
          <h2>تكوين امتحان</h2>
          <FormInput
            type="text"
            name="Title"
            handleChange={onChangeTitle}
            value={quiz.Title}
            label="ادخل عنوان للامتحان"
          />
          <FormInput
            type="text"
            name="subTitle"
            handleChange={onChangeTitle}
            value={quiz.subTitle}
            label="بيانات عن الانتحان"
          />
        </div>
        {Questions &&
          Questions.map((Question, qidx) => (
            <Fragment>
              <div className="card">
                <span className="flex">
                  <span className="FormInput">
                    <FormInput
                      type="text"
                      handleChange={handleQuestionsChange(qidx)}
                      value={Question.quistion}
                      label={Question.quistion === "" && "ادخل السؤال "}
                    />
                  </span>
                  {/*  eslint-disable-next-line */}
                  <a
                    className="center-align text-danger btn-custom exam-link "
                    onClick={handleRemoveQuistion(qidx)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </a>
                </span>
                <ol>
                  {Question.answers &&
                    Question.answers.map((answer, ansidx) => (
                      <label className="flex">
                        <input
                          className="radio-input"
                          onChange={handleChangeRadio(qidx)}
                          value={answer.answer}
                          name={qidx}
                          type="radio"
                          checked={answer.answer === Question.correctAnswer}
                        />{" "}
                        <span className="FormInput">
                          <FormInput
                            type="text"
                            handleChange={handleAnswerChange(qidx, ansidx)}
                            value={answer.answer}
                            label={answer.answer === "" && "ادخل الاجابة "}
                          />
                        </span>
                        {/*  eslint-disable-next-line */}
                        <a
                          className="center-align text-danger btn-custom exam-link "
                          onClick={handleRemoveAnswer(qidx, ansidx)}
                        >
                          <FontAwesomeIcon icon={faMinusSquare} />
                        </a>
                      </label>
                    ))}
                  {/*  eslint-disable-next-line */}
                  <a
                    type="button"
                    onClick={handleAddAnswer(qidx)}
                    className="btn-custom center-align pr-5"
                  >
                    <FontAwesomeIcon icon={faPlus} /> اضافة اجابة
                  </a>
                </ol>
              </div>
            </Fragment>
          ))}
        {/*  eslint-disable-next-line */}
        <a
          className="btn-custom btn-block btn-outline btn-radius"
          onClick={handleAddQuistion}
        >
          <FontAwesomeIcon icon={faPlus} /> اضافة سؤال
        </a>
        {/*  eslint-disable-next-line */}
        <a
          className="btn-custom btn-block btn-dark btn-radius"
          onClick={onSubmit}
        >
          <FontAwesomeIcon icon={faPlus} /> حفظ الامتحان
        </a>
      </div>
    )
  );
};

export default connect(null, { EditExam })(NweTest);
