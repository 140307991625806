import React, { Fragment, useState } from "react";
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addAdv } from "../../../actions/advs.action";
import { setAlert } from "../../../actions/alert";

const AddAdvs = ({ addAdv, setAlert ,schoolID ,studyYear }) => {


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const [adv, setAdv] = useState({
    title: "",
    description: "",
    schoolID
  });
  
  const { title, description } = adv;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };
  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(schoolID)
    if (title === "" || description === "" || file === "") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا الإعلان", "danger");
    } else {
      addAdv(file, title, description,schoolID ,studyYear);
      setAdv({
        title: "",
        description: "",
        schoolID
      });
      setAlert("تمت إضافة الإعلان بنجاح", "success");
      setIsModalVisible(false);
    }
  };

  return (
      <>
      <Button type="primary" onClick={showModal}>
        إضافة إعلان
      </Button>
      <Modal title=" إضافة إعلان" visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={onSubmit}>
              إضافة
            </Button>,
          ]}>
      <label htmlFor="file"> ادخل صور الإعلان </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple />
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الإعلان"
              required
            />

            <label htmlFor="firstName" className="active">
              الاعلان
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdv({ ...adv, description: data });
              }}
            />
      </Modal>
    </>

  );
};

export default connect(null, {
  addAdv,
  setAlert,
})(AddAdvs);
