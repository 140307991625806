import React from "react";
import { Tabs } from "antd";
import AllStudents from "../../dashBourd/Students/AllStudent.page";
import Alumni from "../../dashBourd/Students/Alumni.page";
import Container from "./Container";
import GraduationYearPage from "../../dashBourd/Students/graduationYear.page";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const Demo = ({ match }) => {
  return (
    <Container>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="طلبة الكلية" key="1">
          <AllStudents />
        </TabPane>
        <TabPane tab="خريجي الكلية" key="2">
          <Alumni />
        </TabPane>
        <TabPane tab="ملفات خريجي السنوات" key="3">
          <GraduationYearPage />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default Demo;
