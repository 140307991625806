import React ,{useState , useEffect} from 'react'
import moment from "moment"
import Container from "../Container"
import "./table.css"
import {useSelector, useDispatch} from "react-redux"
import {getMembersBySchool , getOneMember} from "../../../../actions/Members.actions"
import {getEventsByTeacher } from "../../../../actions/Events.action"
import {getCoursesbyTeacherId} from "../../../../actions/Course.action"
import {Layout, Menu ,Avatar ,Image} from 'antd';

import {
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import TablePage from './TablePage'
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



const  Selectable =()=> {
  const {school} =useSelector(state => state.schools)
  const {year} = useSelector(state => state.years)
  const {user} =useSelector(state => state.auth)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getMembersBySchool(school._id));
     dispatch(getEventsByTeacher(user._id))
     year && dispatch(getCoursesbyTeacherId(user._id ,year._id))
  },[])

  
  const {members } =useSelector(state => state.members)
  const {events  } =useSelector(state => state.events)

  const [collapsed ,setcollapsed] =useState(true)
  

  const toggle = () => {
    console.log(collapsed);
    setcollapsed(!collapsed );
  };
    
     const colorList = ["#d4b106" , "#254000" ,"#0050b3" ,"#531dab" ,"#1d39c4" ,"#006d75","#d4b106" , "#254000" ]
    return (
      <Container>
       <Layout id="table">
     
        <Layout className="site-layout">

         <TablePage studyYear={year&& year._id} school={school} events={events} teacherID={user._id} />
          
        </Layout>
      </Layout>
      </Container>
    )
  }


export default Selectable