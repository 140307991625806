import axios from "axios";
import {
  GET_STUDYYEARS,
  GET_STUDYYEAR,
  ADD_STUDYYEAR,
  UPDATE_STUDYYEAR,
  DELETE_STUDYYEAR,
  ERROR_STUDYYEAR,
} from "./types";


// getStudyYears,
//   getActiveStudyYear,
//   addStudyYear,
//   UpdateStudyYear,
//   DeleteStudyYear,
// GET CONFERANCES
export const getStudyYears = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/studyears/`);
    dispatch({
      type: GET_STUDYYEARS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_STUDYYEAR,
      payload: { msg: error.response, status: error.response&& error.response.status },
    });
  }
};

// GET CONFERANCES
export const getActiveStudyYear = () => async (dispatch) => {
  try {
    const {data} = await axios.get(`/api/studyears/active`);
    dispatch({
      type: GET_STUDYYEAR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_STUDYYEAR,
      payload: { msg: error.response, status: error.response&& error.response.status },
    });
  }
};

export const SaveInArchive = (id) => async (dispatch) => {
  try {
    const {data} = await axios.put(`/api/studyears/${id}/archive`);
    dispatch({
      type: UPDATE_STUDYYEAR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_STUDYYEAR,
      payload: { msg: error.response, status: error.response&& error.response.status },
    });
  }
};



export const addStudyYear = (
  nameOfStudyYear,
  startOfStudyYear,
  endOfStudyYear,
  active) => async (
  dispatch
) => {
  try {
    const res = await axios.post("/api/studyears", { nameOfStudyYear,
      startOfStudyYear,
      endOfStudyYear,
      active});
    dispatch({
      type: ADD_STUDYYEAR,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDYYEAR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const UpdateStudyYear = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/studyears/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_STUDYYEAR,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDYYEAR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const DeleteStudyYear = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/studyears/${id}`);
    dispatch({
      type: DELETE_STUDYYEAR,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDYYEAR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
