import React, {  useState,useEffect } from "react";
import { Modal, Button ,Select, message } from 'antd';
import { connect , useSelector,useDispatch } from "react-redux";
import { importFromOtherCourse } from "../../../actions/Topics.action";
import { getCoursesbyTeacherIdAndSubjectID } from "../../../actions/Course.action";
import {ImportOutlined} from "@ant-design/icons"
import { setAlert } from "../../../actions/alert";
const { Option } = Select;

const AddCourse = ({ importFromOtherCourse,courseID ,subject }) => {
  const dispatch = useDispatch()
  useEffect(() => {
   dispatch(getCoursesbyTeacherIdAndSubjectID(subject))
  //  eslint-disable-next-line
  }, [])
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
 const {oldcourses ,loading} = useSelector(state => state.courses)

 const [oldCourseID, setoldCourseID] = useState("");


  const onSubmit = async (e) => {

    if (oldCourseID !== "" ) {
      importFromOtherCourse(courseID,oldCourseID);
      message.success("تم تصدير المادة  بنجاح");
      setoldCourseID("");
    } else {
      message.error(" يجب اختيار و ملء كل الحقول   ");
    }
    setIsModalVisible(false);
  };

  return (
       <>
       {/* eslint-disable-next-line */}
      <a type="primary" onClick={showModal}>
      <ImportOutlined />    
        </a>
      <Modal title="تصدير مادة" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          اضافة
        </Button>,
      ]}
      >
      <h4>إختر المادة</h4>
      <Select 
          style={{ width: "100%" }} 
          onChange={(e) =>setoldCourseID( e )}   
          loading = {loading} 
          name="oldCourseID" 
          value={oldCourseID}
         >
          {oldcourses && oldcourses.map(course =>
          <> 
          {course._id !== courseID &&  <Option value={course._id}>{course.courseName}</Option>}
          </>
         )}
      </Select>
      </Modal>
      </>
  );
};
export default connect(null, {
  importFromOtherCourse,
  setAlert,
})(AddCourse);
