import React, { useState, Fragment } from "react";
import "./Exams.style.scss";

import Spinner from "../../../../components/Layout/Spinner";
import { faTable } from "@fortawesome/free-solid-svg-icons";

const DoExam = ({ exam }) => {
  const [Result, setResult] = useState(exam.Questions);
  const [totalDegree, setTotalDegree] = useState(0);
  const [Final, setFinal] = useState(false);

  // const handleChange = (idx) => (e) => {
  //   // let examm = exam.Exam.find((ex) => ex.nm == e.target.name);
  //   let examm = exam.Questions.find((ex, sdx) => idx === sdx);
  //   let result = examm && examm.correctAnswer === e.target.value;
  //   let check = checkItem(e.target.name);

  //   // check
  //   //   ? setResult(
  //   //       Result.map((re ,idx) =>
  //   //         re.nm == examm.nm ? { examm, result, nm: re.nm } : re
  //   //       )
  //   //     )
  //   //   : setResult([...Result, { examm, result, nm: examm.nm }]);

  // };
  const handleChange = (qidx) => (e) => {
    setResult(
      Result.map((R, sidx) =>
        qidx !== sidx ? R : { ...R, result: R.correctAnswer === e.target.value }
      )
    );
    console.log(Result);
  };

  const onSubmit = () => {
    setFinal(true);
    let total = 0;
    Result.filter((r) => (total += r.result && parseInt(r.degree)));
    setTotalDegree(total);
    console.log(total);
    console.log(Result);
  };

  return !exam ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="my-3">
        <div className="card-container">
          <div className="card text-center">
            <h2>{exam.Title}</h2>
          </div>
          {/* ////// النتائج */}
          {Final ? (
            <Fragment>
              <h1> {"نتيجة الامتحان : " + totalDegree + " درجات "}</h1>
              {Result &&
                Result.map((R, qidx) => (
                  <div
                    className="card"
                    style={{
                      border: R.result ? "1px solid green" : "1px solid red",
                    }}
                  >
                    <div>
                      <div className="grid-2">
                        <h2>{R.question}</h2>
                        <h4>
                          {R.result
                            ? " الاجابة صحيحة " + R.degree + " درجات "
                            : " الاجابة خاطئة 0 درجة"}
                        </h4>
                      </div>
                      <ol className="p-1">
                        {R.answers &&
                          R.answers !== [] &&
                          R.answers.map((a) => (
                            <div className="grid-2 text-right">
                              <label>
                                <input
                                  // onChange={handleChange}
                                  value={a.answer}
                                  name={qidx}
                                  type="radio"
                                  disabled
                                  checked={R.correctAnswer === a.answer}
                                />{" "}
                                <span>
                                  {!R.result && R.correctAnswer === a.answer
                                    ? a.answer + "-> الاجابة الصحيحة "
                                    : a.answer}{" "}
                                </span>
                              </label>
                            </div>
                          ))}
                      </ol>
                    </div>
                  </div>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {/* /////// الامتحان  */}
              {exam.Questions.map((ex, qidx) => (
                <div className="card">
                  <div>
                    <h2>{ex.quistion}</h2>
                    <ol className="p-1">
                      {ex.answers &&
                        ex.answers !== [] &&
                        ex.answers.map((a) => (
                          <div className="grid-2 text-right">
                            <label>
                              <input
                                onChange={handleChange(qidx)}
                                value={a.answer}
                                name={qidx}
                                type="radio"
                              />{" "}
                              <span>{a.answer}</span>
                            </label>
                          </div>
                        ))}
                    </ol>
                  </div>
                </div>
              ))}
              <a
                className="btn btn-block btn-success btn-radius"
                onClick={onSubmit}
              >
                {" "}
                حفظ الامتحان +
              </a>
              {/* ///// نهاية الامتحان */}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DoExam;
