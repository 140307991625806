import React ,{useState , useEffect} from 'react'
import moment from "moment"
import Container from "../Container"
import "./table.css"
import {useSelector, useDispatch} from "react-redux"
import {getEventsByClass } from "../../../actions/Events.action"
import {Layout, Menu ,Avatar ,Image} from 'antd';
import TablePage from './TablePage'
const  Selectable =()=> {
  const {school} =useSelector(state => state.schools)
  const {user} =useSelector(state => state.auth)
  const dispatch = useDispatch()
  useEffect(()=>{
    user.classy && dispatch(getEventsByClass(user.classy))
  },[])
  const {events  } =useSelector(state => state.events)
     const colorList = ["#d4b106" , "#254000" ,"#0050b3" ,"#531dab" ,"#1d39c4" ,"#006d75","#d4b106" , "#254000" ]
    return (
      <Container>
       <Layout id="table">
        <Layout className="site-layout">
         <TablePage school={school} events={events} teacherID={user._id} />
        </Layout>
      </Layout>
      </Container>
    )
  }


export default Selectable