import {
  GET_CLASSES,
  ADD_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  ERROR_CLASS,
  FILTER_CLASSES,
} from "../actions/types";

const inialState = {
  classes: [],
  classy: null,
  filtered: [],
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLASSES:
      return {
        ...state,
        classes: payload,
        loading: false,
      };
    case FILTER_CLASSES:
      return {
        ...state,
        filtered: state.classes.filter((classy) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            classy.name.match(regex) ||
            classy.department.match(regex) ||
            classy.sid.toString().match(regex)
          );
        }),
      };
    case ADD_CLASS:
      return {
        ...state,
        classes: [...state.classes, payload],
        loading: false,
      };
    case UPDATE_CLASS:
      return {
        ...state,
        filtered:
          state.filtered &&
          state.filtered.map((classy) =>
            classy._id === payload._id ? payload : classy
          ),
        classes: state.classes.map((classy) =>
          classy._id === payload._id ? payload : classy
        ),
        loading: false,
      };
    case DELETE_CLASS:
      return {
        ...state,
        classes: state.classes.filter((classy) => classy._id !== payload),
        loading: false,
      };
    case ERROR_CLASS:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
