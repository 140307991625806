import React, { useState } from "react";

import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateAdv } from "../../../actions/advs.action";
import { setAlert } from "../../../actions/alert";
import { Modal, Button } from 'antd';
import {FormOutlined} from "@ant-design/icons"
const UpdateAdvs = ({ updateAdv, correntAdv, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [adv, setAdv] = useState({
    id: correntAdv._id,
    title: correntAdv.title,
    discription: correntAdv.discription,
  });

 

  const { title, discription } = adv;

  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateAdv(adv);
    setAlert("تم التعديل علي بيانات الإعلان", "success");
    setIsModalVisible(false);
  };

  return (
<>
       {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        <FormOutlined />
      </a>
      <Modal title="التعديل علي الاعلان" visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تعديل
        </Button>,
      ]}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الإعلان"
              required
            />

            <label htmlFor="firstName" className="active">
              الاعلان
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={discription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdv({ ...adv, discription: data });
              }}
            />
      </Modal>
    </>

   
  );
};

export default connect(null, {
  updateAdv,
  setAlert,
})(UpdateAdvs);
