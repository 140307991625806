import React, { Fragment, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getNews } from "../../actions/news.action";
import AddNews from "../../components/modals/news/AddAdvs";
import AdvsItems from "./NewsItems";
import Spinner from "../../components/Layout/Spinner";
import { Row, Col } from "antd";
const AdvsPage = ({ getNews, news: { newss, loading } }) => {
  useEffect(() => {
    getNews();
    // eslint-disable-next-line
  }, [loading]);
  return !newss ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        <div className="site-card-wrapper">
          <Row gutter={16}>
            <AddNews />

            {newss &&
              newss.map((news) => (
                <Fragment key={news._id}>
                  <Col sm={6}>
                    <AdvsItems news={news} />
                  </Col>
                </Fragment>
              ))}
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  news: state.news,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getNews })(AdvsPage);
