import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { getGraduationYears } from "../../actions/gradutionsYear.action";
import Spinner from "../../components/Layout/Spinner";
import UploadGraduationYearFile from "../../components/modals/GraduationYears/uploadGraduationYearFile";
import GraduationYearItem from "./GraduationYearsItems";

const GraduationYearPage = ({
  getGraduationYears,
  graduationYears: { graduationYears, loading },
  Class,
}) => {
  useEffect(() => {
    getGraduationYears();
    // eslint-disable-next-line
  }, [loading]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current = 1, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
  };

  const onChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid" style={{ direction: "rtl" }}>
        <UploadGraduationYearFile />

        <table className="striped container-fluid ">
          <thead>
            <tr>
              <th className="right-align">سنة التخرج</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {graduationYears &&
              graduationYears.map((graduation) => (
                <Fragment key={graduation && graduation._id}>
                  <GraduationYearItem
                    graduationYear={graduation}
                    Class={Class}
                  />
                </Fragment>
              ))}
          </tbody>
        </table>
        <div className="center" style={{ direction: "ltr" }}>
          <Pagination
            pageSizeOptions={[10, 20, 30, graduationYears.length]}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={2}
            current={currentPage}
            defaultPageSize={10}
            onChange={onChange}
            total={graduationYears.length}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  graduationYears: state.graduationYears,
});
export default connect(mapStateToProps, {
  getGraduationYears,
})(GraduationYearPage);
