import React, {  useState } from "react";
import { Modal, Button } from 'antd';

import { connect } from "react-redux";
import { ResetPassword } from "../../../actions/Student.action";
import { setAlert } from "../../../actions/alert";

const ResetPasswordStudent = ({ ResetPassword, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const Reset = () => {
    ResetPassword(id);
    setAlert("تم اعادة ضبط كلمة المرور", "success");
    setIsModalVisible(false);
  };
  return (

<>
      {/*  eslint-disable-next-line */}
      <a type="primary" onClick={showModal}>
         اعادة ضبط كلمة المرور   
      </a>
      <Modal title=" تأكيد الطلب" visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={Reset}>
              نعم
            </Button>,
          ]}>
        <p>هل انت متأكد من اعادة ضبط كلمة المرور؟</p>
      </Modal>
    </>
      
      
  );
};

export default connect(null, {
  ResetPassword,
  setAlert,
})(ResetPasswordStudent);
