import React from "react";
import { Link } from "react-router-dom";
import SignUpMember from "../modals/Members/SignUpMember";
import SignUpStudent from "../modals/students/SignUpStudent"
import {Row,Col} from "antd"
const HomePage = () => {
  return (
    <section className="showcase">
      <div className="video-container">
        <video src="/img/scince.mp4" autoPlay muted loop />
      </div>
      <div className="content ">
        <h1 className="center-align ">اختر الشخصية للتسجيل</h1>
        <Row>
          <Col>
            <SignUpStudent />
          </Col>
          <Col>
            <SignUpMember />
          </Col>     
        </Row>
      </div>
    </section>
  );
};

export default HomePage;
