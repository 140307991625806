import React, { useState } from "react";
import { Modal, Button ,Select ,message,DatePicker } from 'antd';
import { connect , useSelector } from "react-redux";
import { addStudyYear } from "../../../actions/stydyYear.action";
import FormInput from "../../form-input/form-input.component";

const { Option } = Select;

const AddStudyYear = ({ addStudyYear ,Disabled  }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


 const {school} = useSelector(state => state.schools)

  const [Year, setYear] = useState({
    nameOfStudyYear : "",
    startOfStudyYear :"",
    endOfStudyYear : "",
    active : "",
  });



  const { nameOfStudyYear, startOfStudyYear, endOfStudyYear, active } = Year;

  const onSubmit = async (e) => {

    if (startOfStudyYear !== "" && endOfStudyYear !== "" ) {
      console.log(Year)
      addStudyYear(nameOfStudyYear, startOfStudyYear, endOfStudyYear, active);
      message.success("تم إضافة خطة العام الدراسي  بنجاح");
      setYear({
        nameOfStudyYear : "",
        startOfStudyYear :"",
        endOfStudyYear : "",
        active : "",
      });
    } else {
      message.error(" يجب اختيار و ملء كل الحقول   ");
    }
    setIsModalVisible(false);
  };

  return (
       <>
      <Button  onClick={showModal} disabled={Disabled}>
        تكوين عام دراسي 
      </Button>
      <Modal title="تكوين عام دراسي" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          اضافة
        </Button>,
      ]}
      >

           <FormInput
              name="nameOfStudyYear"
              type="text"
              handleChange={(e) =>setYear({ ...Year, nameOfStudyYear : e.target.value   })} 
              value={nameOfStudyYear}
              label="العام الدراسي"
              required
            />

              <h4 className="my-1"> بداية العام الدراسي</h4>
              <DatePicker onChange={(date) => setYear({ ...Year, startOfStudyYear : date  })} />

              <h4 className="my-1">نهاية العام الدراسي </h4>
              <DatePicker onChange={(date) => setYear({ ...Year, endOfStudyYear : date  })} />


            <h4 className="my-1">إختر الحالة</h4>
            <Select 
                style={{ width: "100%" }} 
                onChange={(e) =>setYear({ ...Year, active : e   })}   
                name="active" 
                >
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
      </Modal>
      </>
  );
};
export default connect(null, {
  addStudyYear,
})(AddStudyYear);
