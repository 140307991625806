import React from "react";
import Edituserdate from "../../../../components/modals/Members/edit/Edituserdate";
import ChangeImage from "../../../../components/modals/Members/ChangeImage";
import ChangePassword from "../../../../components/modals/Members/edit/ChangePassword";
import {Avatar ,Space ,Image} from "antd"
const userInfo = ({ user }) => {
  const {
    name,
    email,
    nid,
    brthday,
    sex,
    nationality,
    adress,

    phone,
  } = user;
  return (
    <div>
      <div className="card text-right">
       <div className="text-center">
        {!user.Avatar || user.Avatar === "" ? (
         <Avatar  style={{ backgroundColor: '#f56a00', verticalAlign: 'middle',fontSize:"4rem" }} size={180}  gap={2} >
         {user.name}
       </Avatar>
        ) : (
          <Avatar style={{ backgroundColor: '#bae7ff', verticalAlign: 'middle',fontSize:"4rem" }} size={180}  gap={2}
             src={<Image src={user.Avatar} />}
          />
        )}
        
        <h3>{name}</h3>
        <Space size="midle">
          <ChangeImage />
          <ChangePassword />
        </Space>
        </div>
       
    
      <>
        <h2>البيانات الاساسية</h2>
        <h4>
          {" "}
          الرقم الوطني : <span className="muted">{nid}</span>
        </h4>
        <h4>
          {" "}
          البريد الالكتروني : <span className="muted">{email}</span>{" "}
        </h4>

        <h4>
          {" "}
          تاريخ الميلاد : <span className="muted">{brthday}</span>{" "}
        </h4>
        <h4>
          {" "}
          الجنس : <span className="muted">{sex}</span>{" "}
        </h4>
        <h4>
          {" "}
          الجنسية : <span className="muted">{nationality}</span>{" "}
        </h4>

        <h4>
          {" "}
          عنوان السكن : <span className="muted">{adress}</span>{" "}
        </h4>

        <h4>
          {" "}
          رقم الهاتف : <span className="muted">{phone}</span>{" "}
        </h4>

        {/* <ChangeImage />
        <ChangePassword /> <Edituserdate /> */}
      </>
      </div>
    </div>
  );
};

export default userInfo;
