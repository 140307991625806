import React, {  useState } from "react";
import { Modal, Button, message } from 'antd';

import { connect } from "react-redux";

import { changeImage } from "../../../actions/profile";
const ChangeImage = ({ changeImage }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Image, setImage] = useState("");

  const onChangefile = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/gif"
    ) {
      setImage(e.target.files[0]);
    } else {
      message.error(
        "نوع الصورة غير مطابق  يجب ان تكون مطابقة لاحدي هذه الانواع jpg,gif,png,jpeg"
      );
      setIsModalVisible(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    changeImage(Image);
    message.success("تم إضافة الصورة الشخصية");
    setIsModalVisible(false);
    setImage("");
  };

  return (
     <>
      <Button type="primary" onClick={showModal}>
        تغيير الصورة
      </Button>
      <Modal title="تغيير الصورة " visible={isModalVisible} 
       footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تغيير
        </Button>,
      ]}>
      <label htmlFor="file"> ادخل صورة </label>
            <br />

            <input id="file" type="file" onChange={onChangefile} />
      </Modal>
    </>
  

      
  );
};

export default connect(null, {
  changeImage,
})(ChangeImage);
