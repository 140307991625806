import {
  HOMEWORK_ERROR,
  DELETE_HOMEWORK,
  GET_HOMEWORKS,
  ADD_HOMEWORK,
} from "../actions/types";

const initialState = {
  homeworks: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HOMEWORKS:
      return {
        ...state,
        homeworks: payload,
        loading: false,
      };

    case ADD_HOMEWORK:
      return {
        ...state,
        homeworks: [payload, ...state.homeworks],
        loading: false,
      };

    case DELETE_HOMEWORK:
      return {
        ...state,
        homeworks: state.homeworks.filter((file) => file._id !== payload),
        loading: false,
      };
    case HOMEWORK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
