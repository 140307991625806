import axios from "axios";
import {
  GET_CLASSES,
  ADD_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  ERROR_CLASS,
} from "./types";

// get Classes by department 
export const getClassesBySchool = (_id ,activeYear) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/classes/department/${_id}/${activeYear}`);
    dispatch({
      type: GET_CLASSES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CLASS,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET Classes
export const getClassess = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/classes");
    dispatch({
      type: GET_CLASSES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CLASS,
      payload: { msg: error.response, status: error.response && error.response.status },
    });
  }
};

export const addCLass = (NumberOfClass, letterOfClass, department ,studyYear) => async (dispatch) => {
  console.log(department)
  try {
    const res = await axios.post("/api/classes", {NumberOfClass, letterOfClass, department ,studyYear});
    dispatch({
      type: ADD_CLASS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CLASS,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const addStudentToClass = (students , classID) => async (dispatch) =>{
  try {
     const {data} = await axios.put('/api/classes/students' , {students , classID})
     dispatch({
       type : UPDATE_CLASS,
       payload : data
     })
  } catch (error) {
    dispatch({
      type: ERROR_CLASS,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
}

export const updateClass = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(`/api/classes/${formData.id}`, formData, config);
    dispatch({
      type: UPDATE_CLASS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CLASS,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteCLass = (id) => async (dispatch) => {
  try {
    //eslint-disable-next-line
    const res = await axios.delete(`/api/classes/${id}`);
    dispatch({
      type: DELETE_CLASS,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CLASS,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
