import {
  GET_LESSONS,
  ERROR_LESSON,
  UPDATE_LESSON,
  DELETE_LESSON,
  GET_FILES,
  GET_POSTS,
  GET_EXAMS,
  GET_LESSON,
  GET_POST,
  ADD_LESSON,
  CLEAR_LESSON,
} from "../actions/types";

const inialState = {
  lessons: [],
  lesson: null,
  exams: [],
  posts: [],
  post: null,
  // lectures: [],
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LESSON:
      return {
        ...state,
        lesson: payload,
        loading: false,
      };
    // case GET_FILES:
    //   return {
    //     ...state,
    //     lectures: payload,
    //     loading: false,
    //   };
      case ADD_LESSON:
      return {
        ...state,
        lessons: [payload, ...state.lessons],
        loading: false,
      };
    case CLEAR_LESSON:
      return {
        ...state,
        lesson: null,
        loading: false,
      };
    case GET_POSTS:
      return {
        ...state,
        posts: payload,
        loading: false,
      };
    case GET_POST:
      return {
        ...state,
        post: payload,
        loading: false,
      };
    case GET_EXAMS:
      return {
        ...state,
        exams: payload,
        loading: false,
      };

    case GET_LESSONS:
      return {
        ...state,
        lessons: payload,
        loading: false,
      };

    case UPDATE_LESSON:
      return {
        ...state,
        lessons: state.lessons.map((lesson) =>
          lesson._id === payload._id ? payload : lesson
        ),
        loading: false,
      };
    case DELETE_LESSON:
      return {
        ...state,
        lessons: state.lessons.filter((lesson) => lesson._id !== payload),
        loading: false,
      };
    case ERROR_LESSON:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
