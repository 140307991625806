import React, { Fragment, useState } from "react";
import FormInput from "../../form-input/form-input.component";
import { connect, useSelector } from "react-redux";
import { register } from "../../../actions/Student.action";
import { setAlert } from "../../../actions/alert";
import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AddStudent = ({ schoolId, register, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const schoolID = useSelector(
    (state) => state.schools.school && state.schools.school._id
  );

  const [formData, setFormData] = useState({
    nid: "",
    name: "",
    brthday: "",
    sex: "",
    nationality: "",
    adress: "",
    department: schoolID,
    email: "",
    password: "",
    password2: "",
  });

  const {
    nid,
    name,
    brthday,
    sex,
    nationality,
    adress,
    email,
    password,
    password2,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("كلمات المرور غير متطابقة", "danger");
    } else {
      register(formData);
      console.log(formData);
      setAlert("تم إضافة الطالب بنجاح", "success");
      setFormData({
        nid: "",
        name: "",
        brthday: "",
        sex: "",
        nationality: "",
        adress: "",
        department: "",
        email: "",
        password: "",
        password2: "",
        schoolID,
      });
      setIsModalVisible(false);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        {schoolId && schoolId !== "" ? (
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size={64}
          />
        ) : (
          <div className="links">
            <img src="/img/ibtd.png" alt="ibt" style={{ maxWidth: "500px" }} />
            <h3 className="center-align lead text-white lead text-white">
              طالب / ة
            </h3>
          </div>
        )}
      </a>

      <Modal
        title="تسجيل طالب"
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={[
          <Button
            key="submit"
            onClick={onSubmit}
            style={{ margin: "0 0.2rem" }}
          >
            تسجيل
          </Button>,

          <Button type="primary" onClick={handleOk}>
            إلغاء
          </Button>,
        ]}
      >
        <div className="sign-up container">
          <h2 className="title center">تسجيل طالب في النظام</h2>

          <form className="sign-up-form  my-5" onSubmit={onSubmit}>
            <FormInput
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              label="إسم الطالب"
              required
            />
            <FormInput
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              label="البريد الإلكتروني"
              required
            />
            <FormInput
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              label="كلمة المرور"
              required
            />
            <FormInput
              type="password"
              name="password2"
              value={password2}
              onChange={onChange}
              label="تأكيد كلمة المرور"
              required
            />
            <FormInput
              type="text"
              name="nid"
              value={nid}
              onChange={onChange}
              label="الرقم الوطني"
              required
            />

            <FormInput
              type="date"
              name="brthday"
              value={brthday}
              onChange={onChange}
              label="تاريخ الميلاد"
              required
            />
            <label for="sex">إختر الجنس</label>

            <select
              type="text"
              name="sex"
              value={sex}
              onChange={onChange}
              label="الجنس"
              required
              id="sex"
            >
              <option value="اختر الجنس">إختر الجنس</option>
              <option value="ذكر">ذكر</option>
              <option value="انثي">انثي</option>
            </select>

            <FormInput
              type="text"
              name="nationality"
              value={nationality}
              onChange={onChange}
              label="الجنسية"
              required
            />

            <FormInput
              type="text"
              name="adress"
              value={adress}
              onChange={onChange}
              label="عنوان السكن"
              required
            />

            {/* ****************القسم************************ */}

            <Fragment>
              {/* <label for="department" className="my-2">
                    إختر القسم
                  </label>
                  <select
                    name="department"
                    value={department}
                    onChange={onChange}
                  >
                    <option value="">اختر القسم</option>
                    {departments &&
                      departments.map((department) => (
                        <option value={department.title}>
                          {department.title}
                        </option>
                      ))}
                  </select> */}
            </Fragment>
          </form>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  departments: state.departments,
});
export default connect(mapStateToProps, {
  register,
  setAlert,
})(AddStudent);
