import {
  GET_ADVS,
  GET_ADV,
  ADD_ADV,
  UPDATE_ADV,
  ERROR_ADV,
  DELETE_ADV,
} from "../actions/types";

const inialState = {
  advs: [],
  adv: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADVS:
      return {
        ...state,
        advs: payload,
        loading: false,
      };
    case GET_ADV:
      return {
        ...state,
        adv: payload,
        loading: false,
      };
    case ADD_ADV:
      return {
        ...state,
        advs: [...state.advs, payload],
        loading: false,
      };
    case UPDATE_ADV:
      return {
        ...state,
        advs: state.advs.map((adv) =>
          adv._id === payload._id ? payload : adv
        ),
      };
    case DELETE_ADV:
      return {
        ...state,
        advs: state.advs.filter((adv) => adv._id !== payload),
      };
    case ERROR_ADV:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
