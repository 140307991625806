import React, { Fragment, useState } from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import CheckboxStudents from "../../students/CheckboxStudents";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sendEmals } from "../../../actions/Student.action";
import StudentFilter from "../../students/StudentFilter";
import { setAlert } from "../../../actions/alert";

const SendEmailtoStudent = ({
  sendEmals,
  setAlert,
  students: { students, filtered },
}) => {
  const [addModals, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [myFilters, setMyFilters] = useState([]);
  const [message, setmessage] = useState("");

  const handleFilters = (filters) => {
    // const newFilters = { ...myFilters };

    // getFilteredProducts(skip, limit, newFilters);
    setMyFilters(filters);
    console.log("students", myFilters);
  };

  const onSubmit = () => {
    if (message !== "") {
      setAlert(`تم إرسال رساله لعدد ${myFilters.length} من الطلبة`, "success");
      sendEmals(myFilters, message);
      console.log(message, myFilters);
      setmessage("");
      setMyFilters([]);
    } else {
      setAlert(`ارجو كتابة الرسالة للارسال`, "danger");
    }
    setShow(false);
  };
  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a onClick={handleShow} style={{ fontSize: "3rem", direction: "ltr" }}>
        {" "}
        <FontAwesomeIcon icon={faEnvelope} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إرسال رسالة </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="firstName" className="active">
            رسالة
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={message}
            onChange={(event, editor) => {
              const data = editor.getData();
              setmessage(data);
            }}
          />
          <br />
          <div className="text-center">
            <button className="btn bg-dark" onClick={onSubmit}>
              إرسال
            </button>
          </div>

          <br />
          <h3> إختر الطلبة :</h3>

          <StudentFilter />

          {filtered.length > 0 ? (
            <CheckboxStudents
              students={filtered}
              handleFilters={(filters) => handleFilters(filters)}
            />
          ) : (
            <Fragment>
              {students && students !== "" && (
                <CheckboxStudents
                  students={students}
                  handleFilters={(filters) => handleFilters(filters)}
                />
              )}
            </Fragment>
          )}

          <Modal.Footer></Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  students: state.students,
});
export default connect(mapStateToProps, { sendEmals, setAlert })(
  SendEmailtoStudent
);
