import React, {  useState } from "react";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addHomeWorkAnswers } from "../../../actions/homeWorks.actions";
import { setAlert } from "../../../actions/alert";
import { Modal, Button } from 'antd';
import {FontSizeOutlined} from "@ant-design/icons"
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const AddFileLink = ({ exerciseID, addHomeWorkAnswers, setAlert, error }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [answers, setAnswers] = useState("");



  const onSubmit = async (e) => {
    e.preventDefault();
    if (answers === "" ) {
      setAlert("ارجو إضافة     الاجابة", "danger");
    } else {
      addHomeWorkAnswers(answers ,exerciseID);
      if (!error || error === {}) {
        setAlert("حدث خظآ اثناء إضافة الاجابة  ", "danger");
      } else {
        setAlert("تمت الإضافة بنجاح ", "success");
        setAnswers("")
      }
      setIsModalVisible(false);
    }
  };

  return (
      <>
      <Button  onClick={showModal} size="large">
<FontSizeOutlined />      </Button>
      <Modal title="إضافة الاجابات" visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          حفط
        </Button>,
      ]}
      >
        
        <label htmlFor="firstName" className="active">
              الاجابة
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={answers}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAnswers( data );
              }}
            />

      </Modal>
    </>
     
  );
};

export default connect(null, {
  addHomeWorkAnswers,
  setAlert,
})(AddFileLink);
