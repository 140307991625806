import axios from "axios";
// import { setAlert } from "./alert";
import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  DELETE_POST,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  UPDATE_POST,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
  UPDATE_COMMENT,
} from "./types";

// Get posts
export const getPosts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/posts/course/${id}`);

    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add like
export const addLike = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`/api/posts/like/${id}`, config);

    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`/api/posts/unlike/${id}`, config);

    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete post
export const deletePost = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/posts/${id}`);

    dispatch({
      type: DELETE_POST,
      payload: id,
    });

    // dispatch(setAlert('Post Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Add Image

export const addImage = (Image, id) => async (dispatch) => {
  const formData = new FormData();
  for (let i = 0; i < Image.length; i++) {
    formData.append("postImage", Image[i]);
  }

  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
        }
      },
    };
    const res = await axios.put(`/api/posts/${id}`, formData, options);
    dispatch({
      type: UPDATE_POST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add image to comment
export const addImageToComment = (Image, postId, commentId) => async (
  dispatch
) => {
  const formData = new FormData();
  formData.append("commentImage", Image);
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
        }
      },
    };
    const res = await axios.put(
      `/api/posts/comment/${postId}/${commentId}`,
      formData,
      options
    );

    dispatch({
      type: UPDATE_COMMENT,
      payload: res.data,
    });

    // dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add post
export const addPost = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/posts/${formData.courseID}`,
      formData,
      config
    );

    dispatch({
      type: ADD_POST,
      payload: res.data,
    });

    // dispatch(setAlert('Post Created', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get post
export const getPost = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/posts/${id}`);

    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add comment
export const addComment = (postId, formData ) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/posts/comment/${postId}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_POST,
      payload: res.data,
    });

    // dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete comment
export const deleteComment = (postId, commentId) => async (dispatch) => {
  try {
   const {data} =  await axios.delete(`/api/posts/comment/${postId}/${commentId}`);

    dispatch({
      type: UPDATE_POST,
      payload: data,
    });

    // dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
