import React, { useRef, useEffect } from "react";
import { filterStudents } from "../../actions/Student.action";
import FormInput from "../form-input/form-input.component";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const ContactFilter = ({ filterStudents, students: { filtered } }) => {
  useEffect(() => {
    if (filtered === null) {
      text.current.value = "";
    }
  });
  const text = useRef("");

  const onChange = (e) => {
    filterStudents(e.target.value);
  };

  return (
    <form>
      <FormInput
        name="title"
        type="text"
        ref={text}
        onChange={onChange}
        label="ادخل اسم الطالب او القسم ..."
      />
    </form>
  );
};
ContactFilter.propTypes = {
  filterStudents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  students: state.students,
});

export default connect(mapStateToProps, { filterStudents })(ContactFilter);
