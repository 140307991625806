import {
GET_STUDYYEARS,
GET_STUDYYEAR,
ADD_STUDYYEAR,
UPDATE_STUDYYEAR,
DELETE_STUDYYEAR,
ERROR_STUDYYEAR,
} from "../actions/types";

const inialState = {
  years: [],
  year: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STUDYYEARS:
      return {
        ...state,
        years: payload,
        loading: false,
      };
    case GET_STUDYYEAR:
      return {
        ...state,
        year: payload,
        loading: false,
      };
    case ADD_STUDYYEAR:
      return {
        ...state,
        years: [...state.years, payload],
        loading: false,
      };
    case UPDATE_STUDYYEAR:
      return {
        ...state,
        years: state.years.map((year) =>
          year._id === payload._id ? payload : year
        ),
        year : state.year ? payload : state.year
      };
    case DELETE_STUDYYEAR:
      return {
        ...state,
        years: state.years.filter(
          (year) => year._id !== payload
        ),
      };
    case ERROR_STUDYYEAR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
