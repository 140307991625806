import React, {  useState } from "react";
import { Modal, Button  } from 'antd';

import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { updateLesson } from "../../../actions/Lesson.actions";
import { setAlert } from "../../../actions/alert";
import {EditOutlined} from "@ant-design/icons"
  const UpdateLesson = ({ updateLesson, correntLesson, setAlert,courseId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Lesson, setLesson] = useState({
    id: correntLesson._id,
    title: correntLesson.title,
  });

  const { title } = Lesson;
  const onChange = (e) => {
    setLesson({ ...Lesson, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateLesson(Lesson ,courseId);
    setAlert("تم التعديل علي بيانات الدرس", "success");
    setIsModalVisible(false);
  };

  return (
    <>
    {/* eslint-disable-next-line */}
    <a className="lesson-config" onClick={showModal}>
      <EditOutlined />
    </a>
    <Modal title="التعديل على الدرس" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
    footer={[
    <Button key="back" onClick={handleCancel}>
      إلغاء الامر
    </Button>,
    <Button key="submit" type="primary"  onClick={onSubmit}>
      تعديل
    </Button>,
    ]}
    >
    <form onSubmit={onSubmit}>
    <FormInput
      name="title"
      type="text"
      handleChange={onChange}
      value={title}
      label="عنوان الدرس"
      required
    />
    </form>
    </Modal>
  </>

     
  );
};

export default connect(null, {
  updateLesson,
  setAlert,
})(UpdateLesson);
