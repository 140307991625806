import React, {  useState } from "react";
import { Modal, Button ,Menu } from 'antd';

import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { updateClass } from "../../../actions/Class.action";
import { setAlert } from "../../../actions/alert";

  const UpdateClass = ({ updateClass, correntClass, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Classy, setClassy] = useState({
    id: correntClass._id,
    NumberOfClass: correntClass.NumberOfClass,
    letterOfClass: correntClass.letterOfClass,
  });


  const { NumberOfClass, letterOfClass} = Classy;

  const onChange = (e) => {
    setClassy({ ...Classy, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateClass(Classy);
    setAlert("تم التعديل علي بيانات الصف", "success");
    setIsModalVisible(false);
  };

  return (

<>
      <a  onClick={showModal}>
        التعديل علي الفصل
      </a>
   
    <Modal title="التعديل على الإعلان" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        إلغاء الامر
      </Button>,
      <Button key="submit" type="primary"  onClick={onSubmit}>
        تعديل
      </Button>,
    ]}
    >
    <form onSubmit={onSubmit}>
    <FormInput
              name="NumberOfClass"
              type="text"
              handleChange={onChange}
              value={NumberOfClass}
              label="عنوان الصف"
              required
            />
              <FormInput
              name="letterOfClass"
              type="text"
              handleChange={onChange}
              value={letterOfClass}
              label="حرف الصف"
              required
            />
        </form>
    </Modal>
  </>

     
  );
};

export default connect(null, {
  updateClass,
  setAlert,
})(UpdateClass);
