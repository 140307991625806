import React, { useState } from "react";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateNews } from "../../../actions/news.action";
import { setAlert } from "../../../actions/alert";
import { Modal, Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
const UpdateAdvs = ({ updateNews, correntNews, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [news, setNews] = useState({
    id: correntNews._id,
    title: correntNews.title,
    discription: correntNews.discription,
  });

  const { title, discription } = news;

  const onChange = (e) => {
    setNews({ ...news, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateNews(news);
    setAlert("تم التعديل علي بيانات الخبر", "success");
    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        <FormOutlined />
      </a>
      <Modal
        title="التعديل علي الخبر "
        visible={isModalVisible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            تعديل
          </Button>,
        ]}
      >
        <FormInput
          name="title"
          type="text"
          handleChange={onChange}
          value={title}
          label="عنوان الخبر "
          required
        />

        <label htmlFor="firstName" className="active">
          الخبر
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={discription}
          onChange={(event, editor) => {
            const data = editor.getData();
            setNews({ ...news, discription: data });
          }}
        />
      </Modal>
    </>
  );
};

export default connect(null, {
  updateNews,
  setAlert,
})(UpdateAdvs);
