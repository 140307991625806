import React ,{useState , useEffect} from 'react'
import moment from "moment"
import Container from "../Container"
import "./table.css"
import 'moment/locale/ar-ma';
import {useSelector, useDispatch} from "react-redux"
import {getMembersBySchool , getOneMember} from "../../../../actions/Members.actions"
import {getEventsByTeacher } from "../../../../actions/Events.action"
import {getCoursesbyTeacherId} from "../../../../actions/Course.action"
import {Layout, Menu ,Avatar ,Image} from 'antd';

import {
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import TablePage from './TablePage'
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

  

const  Selectable =()=> {
  const {school} =useSelector(state => state.schools)
  const {year} = useSelector(state => state.years)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getMembersBySchool(school._id));
  },[])

  
  const {members } =useSelector(state => state.members)
  const {events  } =useSelector(state => state.events)
  

  const [teacherID , setTeacherID] = useState("")
  
  const onSelectTeacher = (id) => {
    dispatch(getEventsByTeacher(id))
    dispatch(getOneMember(id))
    dispatch(getCoursesbyTeacherId(id , year._id))
    setTeacherID(id)
    console.log(teacherID)
   } 

   
 
  const [collapsed ,setcollapsed] =useState(true)
  

  const toggle = () => {
    console.log(collapsed);
    setcollapsed(!collapsed );
  };
    
     const colorList = ["#d4b106" , "#254000" ,"#0050b3" ,"#531dab" ,"#1d39c4" ,"#006d75","#d4b106" , "#254000" ]
    return (
      <Container>
       <Layout id="table">
        <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        <Header className="site-layout-background" style={{ padding: 0 ,textAlign:"center" }}>
            {React.createElement(collapsed ? LeftOutlined : RightOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
          </Header>          
           <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} theme="light">
              {members && members.map((member,i)=>
              <Menu.Item onClick={() => onSelectTeacher(member._id)} key={i+1} icon={member && member.image ?  
                <Avatar
                  size={24}
                      src={<Image src={member.image} />}
                  /> : <Avatar style={{ backgroundColor: colorList[i],textAlign:"center"}} size="large" >
                  {member.name[0]}
                </Avatar> }>
                {member.name}
                </Menu.Item> )}
            
          </Menu>
        </Sider> 
        <Layout className="site-layout">

         <TablePage studyYear={year&& year._id} school={school} events={events} teacherID={teacherID} />
          
        </Layout>
      </Layout>
      </Container>
    )
  }


export default Selectable