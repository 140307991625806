import React ,{useEffect} from 'react'
import Container from "../Container"
import {useDispatch, useSelector} from "react-redux"
import {getCourse} from "../../../actions/Course.action"
import {Tabs,  Divider } from "antd"
import "./courses.css"
import Topics from '../../../dashBourd/Topics/Topics'
import Exercise from '../../../dashBourd/Topics/Exercises'
import Spinner from "../../../components/Layout/Spinner"
import Posts from "../../../components/posts/Posts";
import { getPosts } from "../../../actions/post";
const { TabPane } = Tabs;

const CoursePage = ({match}) => {
  const dispatch = useDispatch()
  useEffect(() => {
   dispatch(getCourse(match.params.id))
   dispatch(getPosts(match.params.id))
   
    // eslint-disable-next-line
  }, [])
  const {course } = useSelector(state => state.courses)
  const contentStyle = {
    height: "30vh",
    width: "70vw",
    color:" #fff",
    lineHeight: "160px",
    textAlign: "center",
    background : `url(${course &&course.image}) center`  ,
    borderRadius : "5px",
    margin: "5px auto",
  };
    return (
        <Container >
          {!course ? <div className="center"> <Spinner  />  </div>: 
          <>
          <div style={contentStyle} id="header" >
           
          </div>
          <div id="course-body">
          <Tabs defaultActiveKey="1" >
             <TabPane tab="الدروس" key="1"> 
                <Divider orientation="left">المواضيع و الدروس 
              </Divider>
             <Topics  id={course && course._id}/>
          </TabPane>
          <TabPane tab="التمارين و والوجبات" key="2">
          
            <Exercise  id={course && course._id}/>
          </TabPane>
          
          <TabPane tab="جدول المادة" key="3">
            
          </TabPane>
          <TabPane tab=" التعليقات والاستفسارات" key="4">
           <Posts courseID={match.params.id} /> 
          </TabPane>
        </Tabs>
          </div>
         </>}
        </Container>
    )
}

export default CoursePage
