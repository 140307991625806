import {
  GET_NEWSS,
  GET_NEWS,
  ADD_NEWS,
  UPDATE_NEWS,
  ERROR_NEWS,
  DELETE_NEWS,
} from "../actions/types";

const inialState = {
  newss: [],
  news: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NEWSS:
      return {
        ...state,
        newss: payload,
        loading: false,
      };
    case GET_NEWS:
      return {
        ...state,
        news: payload,
        loading: false,
      };
    case ADD_NEWS:
      return {
        ...state,
        newss: [...state.newss, payload],
        loading: false,
      };
    case UPDATE_NEWS:
      return {
        ...state,
        newss: state.newss.map((news) =>
          news._id === payload._id ? payload : news
        ),
      };
    case DELETE_NEWS:
      return {
        ...state,
        newss: state.newss.filter((news) => news._id !== payload),
      };
    case ERROR_NEWS:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
