import React, { useState, Fragment, useEffect } from "react";
import "./Exams.style.scss";
import { getExam } from "../../../../actions/Exams.actions";
import { connect } from "react-redux";
import Spinner from "../../../../components/Layout/Spinner";
import DoExamItem from "./DoExamItem";

const DoExam = ({ match, getExam, exam }) => {
  useEffect(() => {
    getExam(match.params.id);
  }, []);

  return !exam ? (
    <Spinner />
  ) : (
    <Fragment>
      <DoExamItem exam={exam} />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  exam: state.exams.exam,
});

export default connect(mapStateToProps, { getExam })(DoExam);
