import React, { Fragment, useEffect } from "react";
import { connect,useSelector } from "react-redux";
import { getAdvs } from "../../actions/advs.action";
import AddAdvs from "../../components/modals/advs/AddAdvs";
import AdvsItems from "./AdvsItems";
import Spinner from "../../components/Layout/Spinner";
import {Row , Col} from 'antd'
const AdvsPage = ({
  getAdvs,
  advs: { advs, loading },
}) => {
  const {school} = useSelector(state => state.schools)
  const {year} = useSelector(state => state.years)

  useEffect(() => {
    year && getAdvs(school._id , year._id);
    // eslint-disable-next-line
  }, [loading]);
  return !advs ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid"> 
        <div className="site-card-wrapper">
        <Row gutter={16}>
        {year && <AddAdvs schoolID = {school._id} studyYear={year._id} /> }  
        
         {advs.map((adv) => (
          <Fragment key={adv._id}>
             <Col sm={6}>
            <AdvsItems adv={adv} />
            </Col>
          </Fragment>
        ))}
      </Row>
      </div>   
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  advs: state.advs,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getAdvs })(AdvsPage);
