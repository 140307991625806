import React from "react";
import { Tabs } from "antd";
import NewsPage from "../../dashBourd/NEWS/news.page";
import Container from "./Container";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const Demo = ({ match }) => {
  return (
    <Container>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="الاخبار" key="1">
          <NewsPage />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default Demo;
