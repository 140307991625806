import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import { connect } from "react-redux";
import { DeleteSchool } from "../../../actions/school.action";
import { setAlert } from "../../../actions/alert";

const Deleteschool = ({ DeleteSchool, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };
  

  const DeleteC = () => {
    DeleteSchool(id);
    setAlert(" تم حذف  القسم ", "success");
    setIsModalVisible(false);
  };
  return (
<>
     {/*  eslint-disable-next-line */}
      <a onClick={showModal}>
         حذف القسم  
      </a>
      
      <Modal title=" حذف القسم " visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              لا
            </Button>,
            <Button key="submit" type="primary" onClick={DeleteC}>
              نعم
            </Button>,
          ]}>
        <p>  ؟ هل انت متآكد انك تريد حئف هده القسم</p>
        
      </Modal>
</>
      
  );
};

export default connect(null, {
  DeleteSchool,
  setAlert,
})(Deleteschool);
