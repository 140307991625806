import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {getTopicsByCourse} from "../../actions/Topics.action"
import {clearLesson} from "../../actions/Lesson.actions"
import { List ,Typography ,Empty} from "antd"
import DeleteTopic from "../../components/modals/Topics/DeleteTopic"
import DeleteLesson from "../../components/modals/Lessons/DeleteLesson"
import EditTopic from '../../components/modals/Topics/EditTopic'
import EditLesson from '../../components/modals/Lessons/EditLesson'
import {Link} from "react-router-dom"
import AddLesson from '../../components/modals/Lessons/AddLesson'
import ImportLessonsFromCourse from '../../components/modals/Topics/ImportLessonsFromCourse'
const Topics = ({id}) => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getTopicsByCourse(id))
    // eslint-disable-next-line
  },[])
  const {topics} = useSelector(state => state.topics)
  const {school} = useSelector(state => state.schools)
  const {lesson} = useSelector(state => state.lessons)
  const {user} = useSelector(state => state.auth)
  const {course} = useSelector(state => state.courses)

  const ClearLesson = () =>{
   lesson && dispatch(clearLesson())
}
  return (
    <>
    {topics && topics.length > 0 ?
    <>
    {topics.map((topic , j) => 
    <List
    style={{direction : "rtl"}}
      className="mt-1"
      header={<h3>{j+1}. {topic.title} 
              {topic && topic.teacher === user._id && <>
                <DeleteTopic id={topic._id} />  
                <EditTopic  correntTopic={topic}/>
                <AddLesson topic={topic._id} />
                <ImportLessonsFromCourse  courseID={course._id} subject={course.subject} topicID={topic._id}/>
                </>}
              </h3>
              }
      bordered
      dataSource={topic.Lessons}
      renderItem={(item , i) => (
        <>
        
        <List.Item>
          <Typography.Text >{i+1}.{j+1}. </Typography.Text> <Link to={`/teacher/${school.Url}/Lessons/${item._id}`} onClick={ClearLesson} style={{textDecoration: "none" ,fontSize :"1.1rem" ,color : "black"}} >  {item.title}
          </Link>
          {item && topic.teacher === user._id && <>
          <DeleteLesson id={item._id} courseId= {id} />          
          <EditLesson correntLesson={item} courseId= {id} /> </>}
        </List.Item>
       
     
       </>
      )}
    />
    )}
     </> : <Empty />
    }
    </>
  )
}

export default Topics
