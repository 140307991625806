import axios from "axios";
import {
  GET_SUBJECTS,
  ERROR_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  ADD_SUBJECT,
  SUBJECT_FILTERED,
  SUBJECT_FILTEREDـBY_CLASS,
} from "./types";

// Add Course

export const addSubject =
  (Image, subjectname, about, klass, unit, department) => async (dispatch) => {
    const formData = new FormData();
    formData.append("subjectImage", Image);
    formData.append("subjectname", subjectname);
    formData.append("about", about);
    formData.append("klass", klass);
    formData.append("unit", unit);
    formData.append("department", department);
    try {
      const res = await axios.post("/api/subjects", formData);
      dispatch({
        type: ADD_SUBJECT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ERROR_SUBJECT,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

export const subjectFilter = (n) => (dispatch) => {
  dispatch({
    type: SUBJECT_FILTEREDـBY_CLASS,
    payload: n,
  });
};

export const subjectFilterByName = (text) => (dispatch) => {
  dispatch({
    type: SUBJECT_FILTERED,
    payload: text,
  });
};

export const updateSubject = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/subjects/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_SUBJECT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBJECT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// GET CONFERANCES
export const getSubjects = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/subjects/${id}`);
    dispatch({
      type: GET_SUBJECTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBJECT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

export const deleteSubject = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/subjects/${id}`);
    dispatch({
      type: DELETE_SUBJECT,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBJECT,
      payload: {
        msg: error.response && error.response.statusText,
        status: error.response && error.response.status,
      },
    });
  }
};
