import React,{useState} from 'react'
import { Calendar ,Views, momentLocalizer } from 'react-big-calendar'
import moment from "moment"
import {useDispatch ,useSelector} from "react-redux"
import { Modal, Button ,Select } from 'antd';
import { addEvent, deleteEvent , updateEvent} from "../../../../actions/Events.action"
import { getTopicsByCourse } from "../../../../actions/Topics.action"
import Spinner from "../../../../components/Layout/Spinner"
import {Link} from "react-router-dom"
import {clearLesson} from "../../../../actions/Lesson.actions"
import {UploadOutlined} from  "@ant-design/icons"

const {Option} =Select

const TablePage = ({school , events ,teacherID ,studyYear}) => {
const dispatch = useDispatch()
const [isModalVisible, setIsModalVisible] = useState(false);
const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
const [correntEvent, setCorrentEvent] = useState({});


const [Event ,setEvent] = useState({
  id: "",
  start : "",
  end :"",
  course : "",
  teacher : "",
  topic : "",
  lesson :"",
  school :school._id
  ,studyYear
  })

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalUpdateVisible(false);
    setEvent({
    id: "",
    start : "",
    end :"",
    course : "",
    teacher : "",
    topic : "",
    lesson :"",
    school :school._id})
  };

  const DeleteEvent =  (id)=>{ 
    dispatch(deleteEvent(id)) 
    setIsModalUpdateVisible(false);
  }

 

  const getEvent = (event) =>{
    setIsModalUpdateVisible(true)
    dispatch(getTopicsByCourse(event.course._id))
    setCorrentEvent(event)
    setEvent({
      ...Event,
      id:event._id})
    
  }


  const showModal = (slot) => {
    setIsModalVisible(true);
    setEvent({
      ...Event,
      id:correntEvent._id,
      start: moment(slot.start).locale('en').format(),
      end : moment(slot.end).locale('en').format(),
      teacher:teacherID
    })
  };
  const {courses} =useSelector(state => state.courses)
  const {topics} =useSelector(state => state.topics)

  const onSubmit = () =>{
    dispatch(addEvent(Event)) 
    setIsModalVisible(false);
    setEvent({
      id: "",
      start : "",
      end :"",
      course : "",
      teacher : "",
      topic : "",
      lesson :"",
      school :school._id})
  }
  const onAddLesson =() =>{
    dispatch(updateEvent(Event)) 
    setIsModalUpdateVisible(false);
    setEvent({
      id: "",
      start : "",
      end :"",
      course : "",
      teacher : "",
      topic : "",
      lesson :"",
      school :school._id})
  }
  
  const ClearLesson = () =>{
     dispatch(clearLesson())
 }
  const localizer = momentLocalizer(moment)

    return !events ?  <Spinner />    :(
        <>
         <Calendar
          rtl={true}
          selectable
          step={60}
          timeslots={1}
          formats={{ eventTimeRangeFormat: () => null }}
          localizer={localizer}
          messages={{
            date: ' التاريح',
            time: 'الوقت',
            event: 'الدرس',
            allDay: 'كل الايام',
            previous: '<',
            next: '>',
            today :"اليوم",
            week: 'الاسبوع',
            day: '',
           }}
          culture = 'ar-Ma'
          events={events}
          defaultView={window.screen.width > 600 ?  Views.WEEK : Views.DAY  }
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={moment().toDate()}
          min={new Date(2019, 0, 1, 8, 0)} // 8.00 AM
          max={new Date(2020, 0, 1, 18, 0)}
          onSelectEvent={event => getEvent(event)}
          onSelectSlot={(slot) => studyYear && showModal(slot)}
          views={[Views.WEEK ,Views.DAY]}
          slotPropGetter={  (date) => {
            var newStyle = {
                minHeight : "70px"
            };
          
              return {
                  className: "",
                  style: newStyle
              };
        }}
         
          eventPropGetter={
            (event, start, end, isSelected) => {
              var newStyle = {
                  backgroundColor: "#ffa39e",
                  borderRadius: '0px',
                  opacity: 0.8,
                  color: 'black',
                  border: '0px',
                  display: 'block',
                  height: "5rem",
                  textAlign : "center",
                  paddingTop : "20%"                

              };
              if (event.lesson && event.lesson._id && event.lesson._id.length > 0 ){
                newStyle.backgroundColor = "#faad14"
              }
              if (event.lesson && event.lesson.exercise && event.lesson.exercise.length > 0 ){
                newStyle.backgroundColor = "#7cb305"
              }
                return {
                    className: "",
                    style: newStyle
                };
            }
        }/>

        {/* Add Moddels on Select Slot */}
<Modal title="إضافة ماذة الي الجدول"  visible={isModalVisible} onCancel={handleCancel}  footer={[
    <Button key="back" onClick={handleCancel}>
      إلغاء الامر
    </Button>,
    <Button key="submit" type="primary"  disabled={!teacherID.length > 0} onClick={onSubmit}>
      اضافة
    </Button>
    ]}>
      
      <p>{moment(Event.start).format('MMMM Do YYYY, h:mm:ss a') } بداية الحصة </p>
      <p>{moment(Event.end).format('MMMM Do YYYY, h:mm:ss a')} و قت الانتهاء</p>
        <h4>إختر المادة</h4>
        <Select 
          style={{ width: "100%" }} 
          onChange={(e) =>setEvent({ ...Event, course : e   })}   
          name="course" 
          value={Event.course}
         >
          {courses && courses.map(course =>
          <Option value={course._id}>{course.courseName}</Option>)}
        </Select>
  
        </Modal>   


    {/* ///// edit and delete Events */}
    <Modal 
    title={`التعديل علي ${correntEvent.title}`} 
    visible={isModalUpdateVisible} 
    onCancel={handleCancel}  
    footer={[
        <Button 
        key="back" 
        onClick={()=> DeleteEvent(correntEvent._id)} 
        type="danger">
          حذف من الجدول
        </Button>,
        <Button 
        key="back" 
        onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button 
        key="submit" 
        type="primary"  
        disabled={correntEvent && correntEvent.lesson && correntEvent.lesson._id && correntEvent.lesson._id.length  > 0} 
        onClick={onAddLesson}>
          إضافة
        </Button>
      ]}>
         {correntEvent && correntEvent.lesson && correntEvent.lesson._id && correntEvent.lesson._id.length > 0 ? 
                        <>
                        <h3> 
                         
                        للانتقال للدرس اضغط هنا
                        {"  "}
                          <Link to={`/teacher/${school.Url}/Lessons/${correntEvent.lesson._id}`} style={{fontSize : "1.7rem"}}>
                          <UploadOutlined />
                        </Link> </h3> 
                       { correntEvent.lesson.exercise && correntEvent.lesson.exercise.length > 0  && <> 
                        <h3> 
                         للانتقال للتمارين اضغط هنا
                         {"  "}
                           <Link to={`/teacher/${school.Url}/exercise/${correntEvent.lesson.exercise}`} style={{fontSize : "1.7rem"}}>
                           <UploadOutlined />
                         </Link> </h3>
                       </> }
                        
                        
                       </> :
                       <>
                         <h4 className="my-1">إختر الوحدة</h4>
          <Select 
              style={{ width: "100%" }} 
              onChange={(e) =>setEvent({ ...Event, topic : e   })}   
              name="course" 
              value={Event.topic}
              >
              {topics && topics.map(topic =>
              <Option value={topic._id}>{topic.title}</Option>)}
            </Select>
            { Event && Event.topic && Event.topic.length > 0 && 
            <>

            <h4 className="my-1">إختر الدرس</h4>
            <Select 
              style={{ width: "100%" }} 
              onChange={(e) =>setEvent({ ...Event, lesson : e   })}   
              name="lesson" 
              value={Event.lesson}
              >
              {topics && topics.map(topic =>
              <>
              {topic._id === Event.topic &&
                  <>
                    {topic.Lessons.map(lesson =>
                    <Option value={lesson._id}>{lesson.title}</Option>)}
                  </>
              }
              </>
              )}
            </Select>
            </>
          }   
                       </>
                      } 

                      
        </Modal>   
      </>
    )
}

export default TablePage
