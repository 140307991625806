import axios from "axios";
import {
  GET_COURSES,
  ERROR_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  GET_COURSE,
  ADD_COURSE,
  CLEAR_COURSE,
  GET_OLDCOURSES,
  FILTER_COURSES
} from "./types";


// get courses by department 
export const getCoursesBySchool = (_id ,activeYear) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/courses/department/${_id}/${activeYear}`);
    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET CONFERANCES
export const getCourses = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/courses");
    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET CONFERANCES
export const getCoursesbyTeacherIdAndSubjectID = ( subjectID) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/courses/subjects/${subjectID}`);
    dispatch({
      type: GET_OLDCOURSES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// Get One Subject
export const getCourse = (id ) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/courses/${id}`);
    dispatch({
      type: GET_COURSE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// filter Students
export const filterCourses = (text) => (dispatch) => {
  dispatch({ type: FILTER_COURSES, payload: text });
};

// Add Course
export const addCourse = (subject, teacher, Class ,department, studyYear) => async (
  dispatch
) => {
  try {
    const res = await axios.post("/api/courses", {subject, teacher, Class ,department ,studyYear});
    dispatch({
      type: ADD_COURSE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// UPDATE COURSE
export const updateCourse = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/courses/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_COURSE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// DELETE COURSE
export const deleteCourse = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/courses/${id}`);
    dispatch({
      type: DELETE_COURSE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: {
        msg: error.response&&error.response.statusText,
        status: error.response&&error.response.status,
      },
    });
  }
};

///////////////////////////////////////////////////////


export const clearCouse = () => (dispatch) => {
  dispatch({
    type: CLEAR_COURSE,
  });
};


export const getCoursesbyTeacherId = (id ,activeYear) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/courses/${activeYear}/teacher/${id ? id :""  }`);
    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

export const getCoursesByClassId = (id,activeYear) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/courses/${activeYear}/class/${id}`);
    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_COURSE,
      payload: { msg: error.response, status: error.response },
    });
  }
};


