import React, { useState } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../../../actions/profile";
import FormInput from "../../../form-input/form-input.component";
import PropTypes from "prop-types";
import { Modal, Button } from 'antd';

import { setAlert } from "../../../../actions/alert";

const EditProfile = ({ user, updateProfile, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

 

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    nid: user.nid,
    brthday: user.brthday,
    sex: user.sex,
    nationality: user.nationality,
    adress: user.adress,
    phone: user.phone,
  });

  const {
    name,
    email,
    nid,
    brthday,
    sex,
    nationality,
    adress,
    phone,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateProfile(formData);
    setAlert("تم التعديل على البيانات الشخصية", "success");
    setFormData({
      name: user.name,
      email: user.email,
      nid: user.nid,
      brthday: user.brthday,
      sex: user.sex,
      nationality: user.nationality,
      adress: user.adress,
      phone: user.phone,
    });
    setIsModalVisible(false);
  };

  return (
       <>
      <Button type="primary" onClick={showModal}>
      التعديل على البيانات
      </Button>
      <Modal title="تعديل البيانات الشخصية " visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={onSubmit}>
              تعديل
            </Button>,
          ]}>
         <FormInput
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              label="إسم الاستاذ"
            />
            <FormInput
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              label="البريد الإلكتروني"
            />
            <FormInput
              type="nunber"
              name="nid"
              value={nid}
              onChange={onChange}
              label="الرقم الوطني"
            />
            <FormInput
              type="date"
              name="brthday"
              value={brthday}
              onChange={onChange}
              label="تاريخ الميلاد"
            />
            <label for="sex">اختر الجنس</label>
            <select
              type="text"
              name="sex"
              value={sex}
              onChange={onChange}
              label="الجنس"
              id="sex"
            >
              <option value={sex}>{sex}</option>

              <option value="ذكر">ذكر</option>
              <option value="انثي">انثي</option>
            </select>
            <FormInput
              type="text"
              name="nationality"
              value={nationality}
              onChange={onChange}
              label="الجنسية"
            />
            <FormInput
              type="text"
              name="adress"
              value={adress}
              onChange={onChange}
              label="عنوان السكن"
            />
            <FormInput
              type="text"
              name="phone"
              value={phone}
              onChange={onChange}
              label="رقم الهاتف"
            />
      </Modal>
    </>

      
  );
};

EditProfile.propTypes = {
  setAlert: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,

  user: state.auth.user,
});

export default connect(mapStateToProps, { updateProfile, setAlert })(
  EditProfile
);
