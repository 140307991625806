import React from "react"
import { Menu, Layout,Avatar ,Image} from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,HomeOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom"

const { Sider } = Layout;

const Navbar =({value , children}) => {
 

  
    return (
      <Sider
      trigger={null} 
      collapsible
      collapsed={value.collapsed} 
      className="sidenav text-center"
     
      >
          {children}
        </Sider>
    );
  }


export default Navbar