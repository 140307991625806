import React, { useEffect } from 'react'
import { getAdmins } from '../../actions/admin.action'
import {useDispatch ,useSelector}from "react-redux"
const Admins = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
       dispatch(getAdmins)
    //    eslint-disable-next-line
    } ,[])
    const admins = useSelector(state => state.admins)
    return (
        <div>
            {admins && admins.map(admin => <h2>{admin.name}</h2>) }
            
        </div>
    )
}
export default Admins
