import React, { useState } from "react";
import { Modal, Button } from 'antd';
import { connect } from "react-redux";
import { deleteTopic } from "../../../actions/Topics.action";
import { setAlert } from "../../../actions/alert";
import {DeleteOutlined} from "@ant-design/icons"

const DeleteTopic = ({ deleteTopic, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DeleteC = () => {
    deleteTopic(id);
    setAlert("تم حذف الموضوع بنجاح ", "success");
    setIsModalVisible(false);
  };
  return (
  <>
    {/* eslint-disable-next-line */}
    <a onClick={showModal} danger>
       <DeleteOutlined />
    </a>
    <Modal title="حذف موضوع" visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          الغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={DeleteC}>
        تأكيد
      </Button>,
      ]}
    >
      <p>؟ هل انت متأكد انك تريد حذف هدا الموضوع</p>
    </Modal>
  </>
  );
};

export default connect(null, {
  deleteTopic,
  setAlert,
})(DeleteTopic);
