import React, { useState } from "react";
import Alert from "../../components/Layout/Alert";
import { connect } from "react-redux";
import Progress from "../../components/Layout/Progress";
import NavBar from "../../components/Layout/Nav";
import { Menu, Layout, Avatar, Image } from "antd";
import { useSelector } from "react-redux";
import {
  LoginOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  VideoCameraOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";

const { Header, Content } = Layout;

const Container = (props) => {
  const [value, setValue] = useState({
    collapsed: true,
  });

  const user = useSelector((state) => state.auth.user);
  const toggle = () => {
    setValue({
      collapsed: !value.collapsed,
    });
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <NavBar value={value}>
        <>
          <div className="logo my-2">
            {user && user.image ? (
              <Avatar size={64} src={<Image src={user.image} />} />
            ) : (
              <Avatar
                style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
                size="large"
              >
                {user.name[0]}
              </Avatar>
            )}
          </div>

          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item
              key="1"
              icon={<HomeOutlined style={{ fontSize: "1.2rem" }} />}
            >
              <Link to="/admin/collage">الرئيسية</Link>
            </Menu.Item>

            <Menu.Item
              key="4"
              icon={<TeamOutlined style={{ fontSize: "1.2rem" }} />}
            >
              <Link to={`/admin/students`}>طلبة الكلية</Link>
            </Menu.Item>
            <Menu.Item
              key="5"
              icon={<VideoCameraOutlined style={{ fontSize: "1.2rem" }} />}
            >
              <Link to={`/admin/news`}>الاخبار & الاعلانات</Link>
            </Menu.Item>
            <Menu.Item
              key="7"
              icon={<LoginOutlined style={{ fontSize: "1.2rem" }} />}
              onClick={() => props.logout()}
            >
              تسجيل خروج
            </Menu.Item>
          </Menu>
        </>
      </NavBar>

      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ paddingLeft: "2px" }}
        >
          {React.createElement(
            value.collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "0 3px",
            padding: 24,
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <div className="container">
            <Alert />
            <Progress />
          </div>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default connect(null, { logout })(Container);
