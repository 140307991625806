import React, { useState } from "react";
import { Modal, Button } from "antd";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addNews } from "../../../actions/news.action";
import { setAlert } from "../../../actions/alert";

const AddNews = ({ addNews, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [news, setNews] = useState({
    title: "",
    description: "",
  });

  const { title, description } = news;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };
  const onChange = (e) => {
    setNews({ ...news, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || file === "") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا الإعلان", "danger");
    } else {
      addNews(file, title, description);
      setNews({
        title: "",
        description: "",
      });
      setAlert("تمت إضافة الإعلان بنجاح", "success");
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        إضافة خبر
      </Button>
      <Modal
        title=" إضافة خبر"
        visible={isModalVisible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            إضافة
          </Button>,
        ]}
      >
        <label htmlFor="file"> ادخل صور للخبر </label>
        <br />
        <input id="file" type="file" onChange={onChangefile} multiple />
        <FormInput
          name="title"
          type="text"
          handleChange={onChange}
          value={title}
          label="عنوان الخبر"
          required
        />

        <label htmlFor="firstName" className="active">
          الخبر
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={description}
          onChange={(event, editor) => {
            const data = editor.getData();
            setNews({ ...news, description: data });
          }}
        />
      </Modal>
    </>
  );
};

export default connect(null, {
  addNews,
  setAlert,
})(AddNews);
