import React, {  useState } from "react";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addFileLink } from "../../../actions/lectures.actions";
import { setAlert } from "../../../actions/alert";
import { Modal, Button } from 'antd';
import {LinkOutlined} from "@ant-design/icons"
const AddFileLink = ({ lessonID, addFileLink, setAlert, error }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Lesson, setLesson] = useState({
    title: "",
    discription: "",
    category: "video",
    link: "",
  });

  const { title, link } = Lesson;

  const onChange = (e) => {
    setLesson({ ...Lesson, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (link === "" || title === "") {
      setAlert("ارجو إضافة ملف المحاضرة و اسم المحاضرة", "danger");
    } else {
      addFileLink(Lesson ,lessonID);
      if (!error || error === {}) {
        setAlert("حدث خظآ اثناء إضافة الماذة العلمية للمقرر", "danger");
      } else {
        setAlert("تمت الإضافة بنجاح ", "success");
        setLesson({
          title: "",
          discription: "",
          category: "video",
          lesson: lessonID
        });
      }
      setIsModalVisible(false);
    }
  };

  return (
      <>
      <Button  onClick={showModal} size="large">
        <LinkOutlined /> 
      </Button>
      <Modal title="إضافة رابط لدرس مسجل" visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          حفط
        </Button>,
      ]}
      >
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان المحاضرة او الملف"
              required
            />
            <FormInput
              name="link"
              type="text"
              handleChange={onChange}
              value={link}
              label="رابط المحاضرة"
              required
            />

      </Modal>
    </>
     
  );
};

export default connect(null, {
  addFileLink,
  setAlert,
})(AddFileLink);
