import React, { useState } from "react";
import { Modal, Button } from "antd";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addGraduationYear } from "../../../actions/gradutionsYear.action";
import { setAlert } from "../../../actions/alert";
import { PlusCircleOutlined } from "@ant-design/icons";

const AddGraduationYear = ({
  addGraduationYear,
  error,
  setAlert,
  department,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Image, setImage] = useState("");

  const onChangefile = (e) => {
    console.log(e.target.files[0].type);
    if (
      e.target.files[0].type === "text/csv" ||
      e.target.files[0].type === "application/vnd.ms-excel"
    ) {
      setImage(e.target.files[0]);
    } else {
      setAlert("نوع الملف غير مطابق  يجب ان يكون مطابق  csv", "danger");
      setIsModalVisible(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (Image && Image !== "") {
      addGraduationYear(Image, title);
      setAlert("تم تحميل الملف بنجاح", "success");
      setImage("");
      setTitle("");
    } else {
      setAlert(" يجب تحميل الملف ", "danger");
    }
    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        <PlusCircleOutlined />
      </a>
      <Modal
        title="تحميل ملف الخريجين"
        visible={isModalVisible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            تحميل
          </Button>,
        ]}
      >
        <label htmlFor="file"> ادخل الملف</label>
        <br />
        <input id="file" type="file" onChange={onChangefile} />
        <FormInput
          name="title"
          type="text"
          handleChange={(e) => setTitle(e.target.value)}
          value={title}
          label="عنوان "
          required
        />
      </Modal>
    </>
  );
};
export default connect(null, {
  addGraduationYear,
  setAlert,
})(AddGraduationYear);
