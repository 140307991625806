import React, {  useState,useEffect } from "react";
import { Modal, Button ,Select, message } from 'antd';
import { connect , useSelector,useDispatch } from "react-redux";
import { getOldTopicsByOldCourse } from "../../../actions/Topics.action";
import { importLessonFromOtherTopic } from "../../../actions/Lesson.actions";
import { getCoursesbyTeacherIdAndSubjectID } from "../../../actions/Course.action";
import {ImportOutlined} from "@ant-design/icons"
import { setAlert } from "../../../actions/alert";
const { Option } = Select;

const AddCourse = ({ importLessonFromOtherTopic,courseID ,subject,topicID }) => {
  const dispatch = useDispatch()
  useEffect(() => {
   dispatch(getCoursesbyTeacherIdAndSubjectID(subject))
  //  eslint-disable-next-line
  }, [])
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
 const {oldcourses ,loading} = useSelector(state => state.courses)
 const  {oldtopics} = useSelector(state => state.topics)

 const [oldTopicID, setoldTopicID] = useState("");
 const [oldLessonID, setoldLessonID] = useState("");

 
 const onChangeCourse = (e) =>{
  dispatch(getOldTopicsByOldCourse(e))
}
  const onSubmit = async (e) => {

    if (oldTopicID !== "" ) {
      importLessonFromOtherTopic(topicID,oldLessonID);
      message.success("تم تصدير المقرر الدراسي بنجاح");
      setoldTopicID("");
    } else {
      message.error(" يجب اختيار و ملء كل الحقول   ");
    }
    setIsModalVisible(false);
  };

  return (
       <>
       {/* eslint-disable-next-line */}
      <a type="primary" onClick={showModal}>
      <ImportOutlined />    
</a>
      <Modal title="تصدير وحدة" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          اضافة
        </Button>,
      ]}
      >
      <h4>إختر المادة</h4>
      <Select 
          style={{ width: "100%" }} 
          onChange={(e) =>onChangeCourse(e)}   
          loading = {loading} 
         
         >
          {oldcourses && oldcourses.map(course =>
          <> 
          {course._id !== courseID &&  <Option value={course._id}>{course.courseName}</Option>}
          </>
         )}
      </Select>
     {oldtopics &&  oldtopics.length > 0 &&
     <>
     <h4>إختر الوحدة</h4>
      <Select 
          style={{ width: "100%" }} 
          onChange={(e) =>setoldTopicID(e)}             
         >
          {oldtopics && oldtopics.map(topic =>
          <> 
       <Option value={topic._id}>{topic.title}</Option>
          </>
         )}
      </Select>
      </>
      }

   {oldTopicID &&
     <>
     <h4>إختر الدرس</h4>
      <Select 
          style={{ width: "100%" }} 
          onChange={(e) =>setoldLessonID(e)}             
         >
          {oldtopics && oldtopics.map(topic =>
          <> 
          { topic._id === oldTopicID && topic.Lessons.map(lesson =>  <Option value={lesson._id}>{lesson.title}</Option> )  }
          </>
         )}
      </Select>
      </>
      }
      
      </Modal>
      </>
  );
};
export default connect(null, {
  importLessonFromOtherTopic,
  setAlert,
})(AddCourse);
