import React, { useState, Fragment } from "react";
import {Avatar ,Image} from "antd"
const Checkbox = ({ students, handleFilters }) => {
  
  const [checked, setCheked] = useState([]);
  const [selectAll, setselectAll] = useState(false);

  const onChange = () => {
    setselectAll(!selectAll);
    handleFilters([]);
  };
  const handleToggle = (c) => () => {
    // return the first index or -1
    const currentCategoryId = checked.indexOf(c);
    const newCheckedCategoryId = [...checked];
    // if currently checked was not already in checked state > push
    // else pull/take off
    if (currentCategoryId === -1) {
      newCheckedCategoryId.push(c);
    } else {
      newCheckedCategoryId.splice(currentCategoryId, 1);
    }

    setCheked(newCheckedCategoryId);
    handleFilters(newCheckedCategoryId);
  };
  return (
    <Fragment>
      <h3>
        <label>
          <input
            type="checkbox"
            onChange={onChange}
            checked={selectAll}
            value={selectAll}
          />{" "}
          <span>إضافة الطلبة للفصل الدراسي</span>
        </label>
      </h3>
      {students &&
        students.map((c, i) => (
          <h3>
            <label>
              <input
                type="checkbox"
                onChange={handleToggle(c._id)}
                value={checked.indexOf(c._id === -1)}
                disabled={selectAll}
              />{" "}
              <span>
              {c.image ?  
                <Avatar
                  size={64}
                      src={<Image src={c.image} />}
                  /> : <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large" >
                  {c.name[0]}
                </Avatar> } - {c.name} 
              </span>
            </label>
          </h3>
        ))}
    </Fragment>
  );
};

export default Checkbox;
