import React,{useState} from 'react'
import { Calendar ,Views, momentLocalizer } from 'react-big-calendar'
import moment from "moment"
import {useDispatch } from "react-redux"
import { Modal, Button ,Select,Empty } from 'antd';
import Spinner from "../../../components/Layout/Spinner"
import {Link} from "react-router-dom"
import {UploadOutlined} from  "@ant-design/icons"

const {Option} =Select

const TablePage = ({school , events ,teacherID}) => {
const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
const [correntEvent, setCorrentEvent] = useState({});


const [Event ,setEvent] = useState({
  id: "",
  start : "",
  end :"",
  course : "",
  teacher : "",
  topic : "",
  lesson :"",
  school :school._id
  })

  const handleCancel = () => {
    setIsModalUpdateVisible(false);
    setEvent({
    id: "",
    start : "",
    end :"",
    course : "",
    teacher : "",
    topic : "",
    lesson :"",
    school :school._id})
  };



  const getEvent = (event) =>{
    setIsModalUpdateVisible(true)
    setCorrentEvent(event)
    setEvent({...Event, id:correntEvent._id })
  }


  const showModal = (slot) => {
    setEvent({
      ...Event,
      start: moment(slot.start).locale('en').format(),
      end : moment(slot.end).locale('en').format(),
      teacher:teacherID
    })
  };

  const localizer = momentLocalizer(moment)

    return !events ?  <Spinner />    :(
        <>
         <Calendar
          rtl={true}
          selectable
          step={60}
          timeslots={1}
          formats={{ eventTimeRangeFormat: () => null }}
          localizer={localizer}
          messages={{
            date: ' التاريح',
            time: 'الوقت',
            event: 'الدرس',
            allDay: 'كل الايام',
            previous: '<',
            next: '>',
            today :"اليوم",
            week: 'الاسبوع',
            day: '',

        }}
          culture = 'ar-Ma'
          events={events}
          defaultView={window.screen.width > 600 ?  Views.WEEK : Views.DAY  }
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={moment().toDate()}
          min={new Date(2019, 0, 1, 8, 0)} // 8.00 AM
          max={new Date(2020, 0, 1, 18, 0)}
          onSelectEvent={event => getEvent(event)}
          onSelectSlot={(slot) => showModal(slot)}
          views={[Views.WEEK , Views.DAY ]}
          slotPropGetter={  (date) => {
            var newStyle = {
                minHeight : "70px"
            };
          
              return {
                  className: "",
                  style: newStyle
              };
        }}
         
          eventPropGetter={
            (event, start, end, isSelected) => {
              var newStyle = {
                  backgroundColor: "#ffa39e",
                  borderRadius: '0px',
                  opacity: 0.8,
                  color: 'black',
                  border: '0px',
                  display: 'block',
                  height: "5rem",
                  textAlign : "center",
                  paddingTop : "20%"                

              };
              if (event.lesson && event.lesson._id && event.lesson._id.length > 0 ){
                newStyle.backgroundColor = "#faad14"
              }
              if (event.lesson && event.lesson.exercise && event.lesson.exercise.length > 0 ){
                newStyle.backgroundColor = "#7cb305"
              }
                return {
                    className: "",
                    style: newStyle
                };
            }
        }/>

       
    {/* ///// edit and delete Events */}
    <Modal 
    title={`  ${correntEvent.title}`} 
    visible={isModalUpdateVisible} 
    onCancel={handleCancel}  
    footer={[
       
        <Button 
        key="back" 
        onClick={handleCancel}>
           إغلاق
        </Button>,  
      ]}>
         {correntEvent && correntEvent.lesson && correntEvent.lesson._id && correntEvent.lesson._id.length > 0 ?
                        <>
                        <h3> 
                         
                         للانتقال الى الدرس  في مادة{correntEvent.course.courseName} اضغط هنا
                        {"  "}
                          <Link to={`/student/${school.Url}/Lessons/${correntEvent.lesson._id}`} style={{fontSize : "1.7rem"}}>
                          <UploadOutlined />
                        </Link> </h3> 
                       { correntEvent.lesson.exercise && correntEvent.lesson.exercise.length > 0  && <> 
                        <h3> 
                         للانتقال للتمارين لهذا الدرس اضغط هنا
                         {"  "}
                           <Link to={`/student/${school.Url}/exercise/${correntEvent.lesson.exercise}`} style={{fontSize : "1.7rem"}}>
                           <UploadOutlined />
                         </Link> </h3>
                       </> }
                      </> : <Empty />
                      }          
        </Modal>   
      </>
    )
}

export default TablePage
