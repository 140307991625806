import React from "react";
import { connect } from "react-redux";
import { deleteHomeWorks } from "../../../actions/homeWorks.actions";
import { Popconfirm, message } from 'antd';
import { setAlert } from "../../../actions/alert";

const DeleteLecture = ({ deleteHomeWorks, id, setAlert }) => {
  function confirm(e) {
    deleteHomeWorks(id);
    message.success('تم الحذف');
  }
  
  function cancel(e) {
    console.log(e);
    message.error('تم إلغاء العملية');
  }


  return (
    <Popconfirm
    title="هل انت متأكد من ذلك"
    onConfirm={confirm}
    onCancel={cancel}
    okText="نعم"
    cancelText="لا"
  >
    <a href="#">حدف</a>
  </Popconfirm>

  );
};

export default connect(null, {
  deleteHomeWorks,
  setAlert,
})(DeleteLecture);
