import axios from "axios";
import {
  GET_EXERCISES,
  ERROR_EXERCISE,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  GET_EXERCISE,
  ADD_EXERCISE,
  CLEAR_EXERCISE,
  GET_FILES_EXERCISE,

} from "./types";


// get Exercises by Lesson Id 
export const getExerciseByLesson = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/exercises/lesson/${_id}`);
    dispatch({
      type: GET_EXERCISES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EXERCISE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// get Exercises by Lesson Id 
export const getExercisesByCourse = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/exercises/course/${_id}`);
    dispatch({
      type: GET_EXERCISES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EXERCISE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET exercises
export const getExercises = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/exercises");
    dispatch({
      type: GET_EXERCISES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EXERCISE,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// Get One exercise
export const getExercise = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/exercises/${id}`);
    dispatch({
      type: GET_EXERCISE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_EXERCISE,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Add exercise
export const addExercise = (title , lesson , course,deadline) => async (
  dispatch
) => {
  try {
    const res = await axios.post("/api/exercises", {title , lesson , course});
    dispatch({
      type: ADD_EXERCISE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_EXERCISE,
      payload: { msg:err.response && err.response.statusText, status: err.response&& err.response.status },
    });
  }
};

// UPDATE Lesson
export const updateExercise = (formData ) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/exercises/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_EXERCISE,
      payload: res.data,
    });
    
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EXERCISE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// DELETE COURSE
export const deleteExercsie = (id ) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/exercises/${id}`);
    dispatch({
      type: DELETE_EXERCISE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_EXERCISE,
      payload: {
        msg: error.response&&error.response.statusText,
        status: error.response&&error.response.status,
      },
    });
  }
};

///////////////////////////////////////////////////////


export const clearExercise = () => (dispatch) => {
  dispatch({
    type: CLEAR_EXERCISE,
  });
};


// Get All Files Subject
export const getfiles = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/exercises/lectures/${id}`);
    dispatch({
      type: GET_FILES_EXERCISE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ERROR_EXERCISE,
      payload: { msg: err.response, status: err.response },
    });
  }
};



