import React ,{useEffect} from 'react'
import Container from "../Container"
import {useDispatch, useSelector} from "react-redux"
import {getCourse ,getCoursesbyTeacherIdAndSubjectID} from "../../../../actions/Course.action"
import {Tabs,Avatar,spin,Card, Row, Divider } from "antd"
import Topics from '../../../../dashBourd/Topics/Topics'
import Exercise from '../../../../dashBourd/Topics/Exercises'
import StudentPage from  '../../../../dashBourd/Students/Student.page'
import AddTopics from '../../../../components/modals/Topics/AddTopics'
import AddExercise from '../../../../components/modals/Exercise/AddExercise'
import Spinner from "../../../../components/Layout/Spinner"
import Posts from "../../../../components/posts/Posts";
import { getPosts } from "../../../../actions/post";
import ImportFromCourse from '../../../../components/modals/Topics/ImportFromCourse'
import ImportTopicsFromCourse from '../../../../components/modals/Topics/ImportTopicFromCourse'

const { TabPane } = Tabs;
const { Meta } = Card;

const CoursePage = ({match}) => {
  const dispatch = useDispatch()
  const {course ,loading} = useSelector(state => state.courses)

  useEffect(() => {
   dispatch(getCourse(match.params.id))
  }, [])
  const contentStyle = {
    height: "30vh",
    width: "70vw",
    color:" #fff",
    lineHeight: "160px",
    textAlign: "center",
    background : `url(${course &&course.image}) center`  ,
    borderRadius : "5px",
    margin: "5px auto",
  };
    return (
        <Container >
          {!course ? <div className="center"> <Spinner  />  </div>: 
          <>
          <div style={contentStyle} id="header" ></div>
          <div id="course-body">
          <Tabs defaultActiveKey="1" centered style={{direction : "ltr"}}>
          <TabPane tab="الطلبة" key="3">  
            <StudentPage Class={course.Class} />
          </TabPane>
          <TabPane tab=" التعليقات والاستفسارات" key="4">
            <Posts courseID={match.params.id} /> 
          </TabPane>
          <TabPane tab="التمارين و والوجبات" key="2">
          <Divider orientation="right">
                <AddExercise courseID={course && course._id} />
            </Divider>
            <Exercise  id={course && course._id}/>
          </TabPane>
          <TabPane tab="الدروس" key="1"> 
                <Divider orientation="right">المواضيع و الدروس 
                <AddTopics />
                <ImportFromCourse courseID ={course._id}  subject={course.subject}/>
                <ImportTopicsFromCourse courseID ={course._id}  subject={course.subject}/>
              </Divider>
             <Topics  id={course && course._id}/>
          </TabPane>
        </Tabs>
          </div>
         </>}
        </Container>
    )
}

export default CoursePage
