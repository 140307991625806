import axios from "axios";
import {
  HOMEWORK_ERROR,
  DELETE_HOMEWORK,
  GET_HOMEWORKS,
  ADD_HOMEWORK,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";



// Get All Files Subject
export const getExerciseHomeWorks = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/homeworks/exercise/${id}`);
    dispatch({
      type: GET_HOMEWORKS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: HOMEWORK_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get All Files Subject
export const getExerciseHomeWorksByStudent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/homeworks/exercise/${id}/student`);
    dispatch({
      type: GET_HOMEWORKS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: HOMEWORK_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};


export const addHomeWorkFile = (
  file,
  exercise
) => async (dispatch) => {
  const formData = new FormData();
  formData.append("homeWorkDoc", file);
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
        }
      },
    };
    const res = await axios.post(`/api/homeworks/exercise/${exercise}`, formData, options);
    dispatch({
      type: ADD_HOMEWORK, 
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: HOMEWORK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Link
export const addHomeWorkAnswers = (answers ,exerciseID) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/homeworks/exercise/${exerciseID}/answers`,
      {answers},
      config
    );
    dispatch({
      type: ADD_HOMEWORK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: HOMEWORK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};


// Add FILE
export const addHomeWorkeImages = (
  file ,exerciseID
) => async (dispatch) => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("homeWorkImages", file[i]);
  }


  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
        }
      },
    };
    const res = await axios.post(`/api/homeworks/exercise/${exerciseID}/images`, formData, options);
    dispatch({
      type: ADD_HOMEWORK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: HOMEWORK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};



// Delete Lecture
export const deleteHomeWorks = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/homeworks/${id}`);

    dispatch({
      type: DELETE_HOMEWORK,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: HOMEWORK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
