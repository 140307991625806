import React, {useEffect} from 'react'
import Container from "./Container"
import {useSelector ,useDispatch} from "react-redux"
import Advs_mainPage from './Advs.mainPage'
const MainPage = ({match}) => {
    const {school} = useSelector(state => state.schools)
    const {year} = useSelector(state => state.years)

    return (
        <Container url={match.params.url} >
          {school && year && <Advs_mainPage school={school}  year={year}/> }
        </Container>
    )
}

export default MainPage
