import {
  GET_EXERCISES,
  ERROR_EXERCISE,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  GET_EXERCISE,
  ADD_EXERCISE,
  CLEAR_EXERCISE,
 
} from "../actions/types";

const inialState = {
  exercises: [],
  exercise: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EXERCISE:
      return {
        ...state,
        exercise: payload,
        loading: false,
      };
      case ADD_EXERCISE:
      return {
        ...state,
        exercises: [payload, ...state.exercises],
        loading: false,
      };
    case CLEAR_EXERCISE:
      return {
        ...state,
        exercise: null,
        loading: false,
      };
  

    case GET_EXERCISES:
      return {
        ...state,
        exercises: payload,
        loading: false,
      };

    case UPDATE_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.map((exercise) =>
          exercise._id === payload._id ? payload : exercise
        ),
        loading: false,
      };
    case DELETE_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.filter((exercise) => exercise._id !== payload),
        loading: false,
      };
    case ERROR_EXERCISE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
