import React from "react"
import { Tabs } from 'antd';
import AdvsPage from "../../../dashBourd/ADVS/advs.page";
import Container from "./Container";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const Demo = ({match}) =>{

return (
<Container url={match.params.url} >
  <Tabs defaultActiveKey="1" onChange={callback}>
    <TabPane tab="الاعلانات" key="1">
        <AdvsPage />
    </TabPane>
  </Tabs>
  </Container>
)};

export default Demo