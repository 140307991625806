import React  from "react";
import { Link } from "react-router-dom";
import {  useSelector , useDispatch } from "react-redux";
import {clearCouse} from  "../../../../actions/Course.action"
import Spinner from "../../../../components/Layout/Spinner";


import {  Avatar } from 'antd';


const ResearchItem = ({ course }) => {
   const dispatch = useDispatch()
  const {  courseName, image  } = course;
  const {Url}  = useSelector(state => state.schools.school)
  return !course ? (
    <Spinner />
  ) : (
<Link to={`/teacher/${Url}/courses/${course._id}`} id="courseitem">
  <div className="ant-card ant-card-bordered ant-card-hoverable" 
   style={{width: "240px"}}
   onClick={()=> dispatch(clearCouse())}
  >
    <div className="ant-card-cover">
      <img alt={courseName} src={image}/>
      <Avatar shape="square" style={{  verticalAlign: 'middle' }}  >
       {course.Class && course.Class.NumberOfClass + course.Class.letterOfClass}
      </Avatar>
    </div>
    <div className="ant-card-body">
      <div className="ant-card-meta">
        <div className="ant-card-meta-detail">
          <div className="ant-card-meta-title">{course.subject.subjectname}
          </div>
        </div>
      </div>
    </div>
    </div>
  </Link>
  );
};

export default ResearchItem;
