import React, { createElement, useState } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addLike, removeLike, deletePost ,deleteComment } from "../../actions/post";
import { Comment, Tooltip, Avatar,Space  ,Divider} from 'antd';
import moment from 'moment';
import { DislikeOutlined, LikeOutlined, LikeFilled ,CommentOutlined ,WechatOutlined ,DeleteOutlined } from '@ant-design/icons';
import CommentForm from '../post/CommentForm';

const PostItem = ({
  addLike,
  removeLike,
  deletePost,
  deleteComment,
  auth,
  post: { _id, text, name, avatar, user, 
    likes,
     image, comments, date },
  showActions,
}) => {
  const [Image, setImage] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const handleShow = (im) => {
    setImage(im);
    setShowImage(true);
  };
  const handleHide = () => setShowImage(false);


  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);


  const actions = [
    <Space size="middle">
    <Tooltip key="comment-basic-like" title="إعجاب">
      <span onClick={() => addLike(_id)}>
        {createElement(likes.length > 0 ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{likes.length}</span>
      </span>
    </Tooltip> 
    <Tooltip key="comment-basic-dislike" title="إالغاء الاعجاب">
      <span onClick={() => removeLike(_id)}>
        {React.createElement( DislikeOutlined)}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>
    {!auth.loading && user === auth.user._id && (
    <Tooltip key="comment-basic-dislike" title="حذف">
      <span onClick={() => deletePost(_id)}>
        {React.createElement(DeleteOutlined )}
      </span>
    </Tooltip>
    )}
   
    </Space>  
  ];

  return (
    // <div className="post bg-white p-1 my-1">
    //   <div>
    //     <Link to={`/profile/${user}`}>
    //       {avatar && avatar !== "" ? (
    //         <img className="round-img" src={avatar} alt="" />
    //       ) : (
    //         <img className="round-img" src="/uploads/avatar.png" alt="" />
    //       )}
    //       <h4>{name}</h4>
    //     </Link>
    //   </div>
    //   <div>
    //     {" "}
    //     {image &&
    //       image.length > 0 &&
    //       image.map((im, i) => (
    //         <img
    //           src={im}
    //           alt="post image"
    //           className="post-image p"
    //           onClick={() => handleShow(im)}
    //         />
    //       ))}
    //     <p className="my-1">{text}</p>
    //     <p className="post-date">
    //       نشر بتاريخ :<Moment format="YYYY/MM/DD">{date}</Moment>
    //     </p>
    //     {showActions && (
    //       <Fragment>
    //         <button
    //           onClick={() => addLike(_id)}
    //           type="button"
    //           className="btn-custom btn-light"
    //         >
    //           <FontAwesomeIcon icon={faThumbsUp} />
    //           <span>{likes.length > 0 && <span>{likes.length}</span>}</span>
    //         </button>
    //         <button
    //           onClick={() => removeLike(_id)}
    //           type="button"
    //           className="btn-custom btn-light"
    //         >
    //           <FontAwesomeIcon icon={faThumbsDown} />{" "}
    //         </button>
    //         <Link to={`/posts/${_id}`} className="btn-custom btn-primary">
    //           ردود
    //           {"  "}
    //           {comments.length > 0 && (
    //             <span className="comment-count">{comments.length}</span>
    //           )}
    //         </Link>
    //         {!auth.loading && user === auth.user._id && (
    //           <>
    //             <button
    //               onClick={() => deletePost(_id)}
    //               type="button"
    //               className="btn-custom btn-danger"
    //             >
    //               <FontAwesomeIcon icon={faTrashAlt} />
    //             </button>
    //             <AddImagetoPost id={_id} />
    //           </>
    //         )}
    //         <div
    //           className={showImage ? "display" : "hide"}
    //           onClick={() => handleHide()}
    //         >
    //           <div className="content-image">
    //             <img src={Image} alt="Post Image" />
    //           </div>
    //         </div>
    //       </Fragment>
    //     )}
    //   </div>
    // </div>
    <Comment
    actions={actions}
    author={<a>{name}</a>}
    avatar={
      avatar ?  <Avatar size={64} src={<Image src={avatar} /> }  style={{margin: " 0  1.1rem"}}/> :
               <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' ,margin: " 0  1.1rem" }} size="large" >
        {name[0]}
      </Avatar> 
    }
    content={
      <p>
       {text}
      </p>
    }
    datetime={
      <Tooltip title={moment(date).format('YYYY-MM-DD HH:mm:ss')}>
        <span>{moment(date).fromNow()}</span>
      </Tooltip>
    }
  >
     {comments && comments.length > 0 &&
      <div style={{margin: " 0 2.5rem"}}>
    <Divider orientation="left">
    <CommentOutlined />
     <span className="comment-action">{comments.length} الردود</span>
    </Divider>
    { comments.map(comment => 
   
    <Comment
    actions={[
      <Space size="middle">
      {!auth.loading && comment.user === auth.user._id && (
        <Tooltip key="comment-basic-dislike" title="حذف">
          <span onClick={() => deleteComment(_id, comment._id)}>
            {React.createElement(DeleteOutlined )}
          </span>
        </Tooltip>
        )}
        </Space>
    ]}
    author={<a>{comment.name}</a>}
    avatar={
      avatar ?  <Avatar size={64} src={<Image src={comment.avatar} /> }  style={{ marginLeft : "1rem" }}/> :
               <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' , marginLeft : "1rem" }} size="midle" >
        {comment.name[0]}
      </Avatar> 
    }
    content={
      <p>
       {comment.text}
      </p>
    }
    datetime={
      <Tooltip title={moment(comment.date).format('YYYY-MM-DD HH:mm:ss')}>
        <span>{moment(comment.date).fromNow()}</span>
      </Tooltip>
    }
  ></Comment>
 )}
 <CommentForm postId={_id} />
  </div>
}
  </Comment>
  );
};
PostItem.defaultProps = {
  showActions: true,
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addLike, removeLike, deletePost ,deleteComment })(
  PostItem
);
