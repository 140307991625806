import {
  GET_SCHOOLS,
  GET_SCHOOL,
  ADD_SCHOOL,
  UPDATE_SCHOOL,
  ERROR_SCHOOL,
  DELETE_SCHOOL,
} from "../actions/types";

const inialState = {
  schools: [],
  school: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCHOOLS:
      return {
        ...state,
        schools: payload,
        loading: false,
      };
    case GET_SCHOOL:
      return {
        ...state,
        school: payload,
        loading: false,
      };
    case ADD_SCHOOL:
      return {
        ...state,
        schools: [...state.schools, payload],
        loading: false,
      };
    case UPDATE_SCHOOL:
      return {
        ...state,
        schools: state.schools.map((school) =>
          school._id === payload._id ? payload : school
        ),
        school : state.school ? payload : state.school
      };
    case DELETE_SCHOOL:
      return {
        ...state,
        schools: state.schools.filter(
          (school) => school._id !== payload
        ),
      };
    case ERROR_SCHOOL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
