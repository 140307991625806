import React, { Fragment, useState } from "react";
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { BlockingMember } from "../../../actions/Members.actions";
import { setAlert } from "../../../actions/alert";

const BlockMember = ({ BlockingMember, id, Blocking, name, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DeleteC = () => {
    BlockingMember(id);
    Blocking
      ? setAlert("تم إلغاء حجب هذا الحساب", "success")
      : setAlert("تم  حجب هذا الحساب", "success");
      setIsModalVisible(false);
    };
  return (
     <>
      <a type="primary" onClick={showModal}>
      {Blocking ? (
          <>
            <FontAwesomeIcon icon={faUnlock} /> إلغاء حجب هذا الحساب
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faUserLock} /> حجب هذا الحساب
          </>
        )}{" "}      </a>
      <Modal title="تأكيد الطلب" visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={DeleteC}>
              نعم
            </Button>,
          ]}>
        <p> {Blocking
            ? `   هل انت متأكد انك تريد إلغاء حجب حساب الاستاذ ${name}`
            : `   هل انت متآكد انك تريد  حجب حساب الاستاذ ${name}`}</p>

      </Modal>
    </>

 
  );
};

export default connect(null, {
  BlockingMember,
  setAlert,
})(BlockMember);
