import {
  FILE_ERROR,
  DELETE_FILE,
  GET_FILES,
  ADD_FILE,
  REMOVE_ERRORS,
  UPDATE_FILE,
} from "../actions/types";

const initialState = {
  files: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FILES:
      return {
        ...state,
        files: payload,
        loading: false,
      };

    case ADD_FILE:
      return {
        ...state,
        files: [payload, ...state.files],
        loading: false,
      };
    case UPDATE_FILE:
      return {
        ...state,
        files: state.files.map((file) =>
          file._id === payload._id ? payload : file
        ),
        loading: false,
      };
    case DELETE_FILE:
      return {
        ...state,
        files: state.files.filter((file) => file._id !== payload),
        loading: false,
      };
    case FILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case REMOVE_ERRORS:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
}
