import axios from "axios";
import {
  ADD_EXAM,
  GET_EXAMS,
  GET_EXAM,
  DELETE_EXAM,
  UPDATE_EXAM,
  EXAM_ERROR,
} from "./types";

// Get All Exams for this Subject
export const getExams = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/exams/${id}`);
    dispatch({
      type: GET_EXAMS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EXAM_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get One Exam for this Subject
export const getExam = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/exams/exam/${id}/`);
    dispatch({
      type: GET_EXAM,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EXAM_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Add Exams

export const addExams = (Data) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  console.log(Data);
  try {
    const res = await axios.post(`/api/exams/${Data.subject}`, Data, config);

    dispatch({
      type: ADD_EXAM,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EXAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Link

export const addExamLink = (Exam) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };

  try {
    const res = await axios.post(
      `/api/exams/${Exam.subject}/examlink`,
      Exam,
      config
    );
    dispatch({
      type: ADD_EXAM,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EXAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Edit Exams
export const EditExam = (Exam) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };

  try {
    console.log(Exam);
    const res = await axios.put(`/api/exams/${Exam.id}`, Exam, config);
    dispatch({
      type: UPDATE_EXAM,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EXAM_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Delete Course
export const deleteExam = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/exams/${id}`);

    dispatch({
      type: DELETE_EXAM,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: EXAM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
