import axios from "axios" 
import {GET_ADMINS , ERROR_ADMIN , DELETE_ADMIN} from "./types"

export const getAdmins = () =>async (dispatch) =>{
    try {
        const {data} = await axios.get("/api/users");
        dispatch=({
            type : GET_ADMINS ,
            payload : data
        })
    } catch (err) {
        dispatch({
            type:ERROR_ADMIN,
            payload : { msg: err.response, status: err.response && err.response.status },
        })
    }

}