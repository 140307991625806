import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addHomeWorkeImages } from "../../../actions/homeWorks.actions";
import { setAlert } from "../../../actions/alert";
import {PaperClipOutlined,FileImageOutlined} from "@ant-design/icons"

const AddFile = ({  exerciseID, addHomeWorkeImages, setAlert  }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  

  const [file, setfile] = useState("");

  const onChangefile = (e) => {
    setfile(e.target.files);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (file === "" ) {
      setAlert("ارجو إضافة صور الاجابة    ", "danger");
    } else {
      addHomeWorkeImages(file, exerciseID);
      setAlert("تمت الإضافة بنجاح ", "success");
  
      setfile("");
    }
    setIsModalVisible(false);
  };

  return (
      <>
      <Button onClick={showModal} size="large">
         <FileImageOutlined />
      </Button>
      <Modal title="تحميل صور الاجابة" visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          حفط
        </Button>,
      ]}
      >
            <label htmlFor="file">ادخل صور الاجابة  لتحميل</label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple  />
      </Modal>
    </>
      
      
  );
};


export default connect(null, {
  addHomeWorkeImages,
  setAlert,
})(AddFile);
