import React, {  useState } from "react";
import { Modal, Button ,Menu } from 'antd';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { connect } from "react-redux";
import { updateLesson } from "../../../actions/Lesson.actions";
import { setAlert } from "../../../actions/alert";
import {EditOutlined} from "@ant-design/icons"
import {FontSizeOutlined } from "@ant-design/icons"

  const UpdateLesson = ({ updateLesson, correntLesson, setAlert,courseId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Lesson, setLesson] = useState({
    id: correntLesson._id,
    description: correntLesson.description,
  });

  const { description } = Lesson;

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLesson(Lesson ,courseId);
    setAlert("تم التعديل علي بيانات الدرس", "success");
    setIsModalVisible(false);
  };

  return (
    <>
    <Button size="large" onClick={showModal}><FontSizeOutlined /></Button>
   
    <Modal title="إضافة  نص" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
    footer={[
    <Button key="back" onClick={handleCancel}>
      إلغاء الامر
    </Button>,
    <Button key="submit" type="primary"  onClick={onSubmit}>
      إضافة
    </Button>,
    ]}
    >
    <form onSubmit={onSubmit}>
    <label htmlFor="firstName" className="active">
              النص
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setLesson({ ...Lesson, description: data });
              }}
            />
    </form>
    </Modal>
  </>

     
  );
};

export default connect(null, {
  updateLesson,
  setAlert,
})(UpdateLesson);
