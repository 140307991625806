import React from "react";
import { connect } from "react-redux";
import EditGraduationYear from "../../components/modals/GraduationYears/EditGraduationYear";
import DeleteGraduationYear from "../../components/modals/GraduationYears/DeleteGraduationYear";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const GraduationYearItem = ({ graduationYear, Class }) => {
  const { _id, title } = graduationYear;

  const menu = (
    <Menu>
      <Menu.Item>
        <EditGraduationYear correntGraduationYear={graduationYear} />
      </Menu.Item>
      <Menu.Item>
        <DeleteGraduationYear id={_id} />
      </Menu.Item>
    </Menu>
  );
  return (
    <tr>
      <td className="right-align">{title}</td>

      <td className="right-align">
        {!Class && (
          <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              المزيد
              <DownOutlined />
            </a>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(GraduationYearItem);
