import React, { useState } from "react";
import { connect ,useSelector } from "react-redux";
import { addComment } from "../../actions/post";

const CommentForm = ({ postId, addComment }) => {
  const [text, setText] = useState("");

  const {user} = useSelector(state => state.auth)
  return (     
      <form
        className="form my-1"
        onSubmit={(e) => {
          e.preventDefault();
          addComment(postId, { text , Rolls: user.rolls}  );
          setText("");
        }}
      >
        <textarea
          name="text"
          cols="20"
          rows="2"
          placeholder="اكتب الرد او الاستعسار هنا ..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        />
        <input type="submit" className="btn btn-dark my-1" value="إرسال الرد" />
      </form>
  );
};



export default connect(null, { addComment })(CommentForm);
