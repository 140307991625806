import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import { deleteComment } from "../../actions/post";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const CommentItem = ({
  postId,
  comment: { _id, text, name, avatar, user, date, image },
  auth,
  deleteComment,
}) => {
  const [showImage, setShowImage] = useState(false);
  const handleHide = () => setShowImage(false);
  return (
    <div className="post bg-white p-1 my-1">
      <div>
        <Link to={`/profile/${user}`}>
          {avatar && avatar !== "" ? (
            <img className="round-img" src={avatar} alt="" />
          ) : (
            <img className="round-img" src="/uploads/avatar.png" alt="" />
          )}
          <h4>{name}</h4>
        </Link>
      </div>
      <div>
        {image && <img src={image} onClick={() => setShowImage(true)} />}
        <p className="my-1">{text}</p>
        <p className="post-date">
          نشر بتاريخ : <Moment format="YYYY/MM/DD">{date}</Moment>
        </p>
        {!auth.loading && user === auth.user._id && (
          <>
            <button
              onClick={() => deleteComment(postId, _id)}
              type="button"
              className="btn-custom  btn-danger"
            >
              <FontAwesomeIcon icon={faTrashAlt} />{" "}
            </button>
          </>
        )}
      </div>
      <div
        className={showImage ? "display" : "hide"}
        onClick={() => handleHide()}
      >
        <div className="content-image">
          <img src={image} alt="Post Image" />
        </div>
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteComment })(CommentItem);
