import React, { Fragment, useEffect } from "react";
import EditExamItem from "./EditExamItem";
import { getExam } from "../../../../actions/Exams.actions";
import { connect } from "react-redux";
import Spinner from "../../../../components/Layout/Spinner";

const EditExam = ({ match, EditExam, exam, getExam }) => {
  useEffect(() => {
    getExam(match.params.id);
    // eslint-disable-next-line
  }, []);
  return !exam ? (
    <Spinner />
  ) : (
    <Fragment>
      <EditExamItem exam={exam} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  exam: state.exams.exam,
});

export default connect(mapStateToProps, { getExam })(EditExam);
