import {
  GET_MEMBERS,
  ADD_MEMBER,
  ERROR_MEMBER,
  DELETE_MEMBER,
  UPDATE_MEMBER,
  GET_MEMBER,
} from "../actions/types";

const inialState = {
  members: [],
  member: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: payload,
        loading: false,
      };
    case GET_MEMBER:
      return {
        ...state,
        member: payload,
        loading: false,
      };
    case UPDATE_MEMBER:
      return {
        ...state,
        members: state.members.map((member) =>
          member._id === payload._id ? payload : member
        ),
        loading: false,
      };
    case DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter((member) => member._id !== payload),
        loading: false,
      };
    case ADD_MEMBER:
      return {
        ...state,
        members: [...state.members, payload],
        loading: false,
      };
    case ERROR_MEMBER:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
