import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DeleteAdv from "../../components/modals/advs/DeleteAdvs";
import EditAdvs from "../../components/modals/advs/EditAdvs";
import { Card } from 'antd';
const { Meta } = Card;

const AdvsItem = ({ adv, isAuthenticated, user }) => {
  const { _id, image, title, discription, updatedAt } = adv;



  return (
   <Fragment>
    <Card
    hoverable
    style={{ width: 240 ,height : 350 ,marginTop : 10 }}
    cover={<img alt={title} src={image}  height="170"/>}

    >  
    <Meta title={title} />
    <small>{updatedAt}</small>
    <p dangerouslySetInnerHTML={{ __html: discription }} style={{fontSize:"0.7rem", height:"120px" ,overflow:"hidden"}}></p>
    <p>
    {isAuthenticated && user &&  (
    <Fragment>
      <EditAdvs correntAdv={adv} />
      <DeleteAdv id={_id} />
    </Fragment>
    )}
    </p>
    </Card>
    </Fragment>
  )
};
AdvsItem.propTypes = {
  adv: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AdvsItem);
