import axios from "axios";
import {
  GET_GRADUTIONYEARS,
  ADD_GRADUTIONYEAR,
  UPDATE_GRADUTIONYEAR,
  DELETE_GRADUTIONYEAR,
  ERROR_GRADUTIONYEAR,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";

// GET Newss
export const getGraduationYears = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/graduationyear`);
    dispatch({
      type: GET_GRADUTIONYEARS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GRADUTIONYEAR,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};

export const addGraduationYear = (file, title) => async (dispatch) => {
  const formData = new FormData();
  formData.append("GraduationYear", file);
  formData.append("title", title);
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 5000);
        }
      },
    };
    const res = await axios.post("/api/graduationyear", formData, options);
    dispatch({
      type: ADD_GRADUTIONYEAR,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GRADUTIONYEAR,
      payload: {
        msg: error.response && error.response.statusText,
        status: error.response && error.response.status,
      },
    });
  }
};

export const UpdateGraduationYear = (id, title) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const { data } = await axios.put(
      `/api/graduationyear/${id}`,
      { title },
      config
    );
    dispatch({
      type: UPDATE_GRADUTIONYEAR,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_GRADUTIONYEAR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteGraduationYear = (id) => async (dispatch) => {
  try {
    //eslint-disable-next-line
    const res = await axios.delete(`/api/graduationyear/${id}`);
    dispatch({
      type: DELETE_GRADUTIONYEAR,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_GRADUTIONYEAR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
