import React from "react";
import "./Home.styles.scss";
import { useSelector } from "react-redux";
import UserInfo from "./homeComponents/userInfo";
import Spinner from "../../../components/Layout/Spinner";
import Container from "./Container"
import {Link} from "react-router-dom"
import AffiliationToSchool from "../../../components/modals/students/AffiliationToSchool";
import {Row , Col} from "antd"

const Home = () => {
  const {user ,loading} = useSelector(state => state.auth)
  const { department } =user
  return loading ? (
    <Spinner />
  ) : (
    <Container >
        <div >
          <Row>
          <Col  sm={24} xs={24} md={8}>
            <UserInfo user={user} />
          </Col>
          <Col xs={24} sm={24} md={16}>
            <div className="text-center my-3">
             
            <h3 className ="my-2 ">
                القسم
            </h3>
               {department && department._id && department._id.length > 0  ?
                  <div className ="my-2">
                    <Link  className="card-link" to={`/student/${user.department.Url}`}>
                    الدخول للوحة  لطالب 
                    </Link> 
                  </div> : 
                  <AffiliationToSchool id={user._id} />
                }            
            </div>
            
            
          </Col>
         
        
        </Row>
      </div>
    </Container> 
  );
};


export default Home;
