import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addFile } from "../../../actions/lectures.actions";
import { setAlert } from "../../../actions/alert";
import {PaperClipOutlined,FileImageOutlined} from "@ant-design/icons"

const AddFile = ({  lessonID, addFile, setAlert ,correntCategory }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  

  const [Lesson, setLesson] = useState({
    title: "",
    discription: "",
    category: correntCategory,
    lesson: lessonID,
  });

  const { title, discription, category, lesson } = Lesson;

  const [file, setfile] = useState("");

  const onChangefile = (e) => {
    console.log(lesson)
    setfile(e.target.files[0]);
  };
  const onChange = (e) => {
    setLesson({ ...Lesson, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    if (file === "" || title === "") {
      setAlert("ارجو إضافة ملف المحاضرة و اسم المحاضرة", "danger");
    } else {
      addFile(file, title, discription, category, lesson);

      setAlert("تمت الإضافة بنجاح ", "success");
      setLesson({
        title: "",
        discription: "",
        category: correntCategory,
        lesson: lessonID,
      });
      setfile("");
    }
    setIsModalVisible(false);
  };

  return (
      <>
      <Button onClick={showModal} size="large">
        {category === "image" && <FileImageOutlined />}
        {category === "doc" && <PaperClipOutlined />}
      </Button>
      <Modal title={category === "image" ? "تحميل صورة" : "تحميل ملف"} visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          حفط
        </Button>,
      ]}
      >
            <label htmlFor="file">
            {category === "image" ? "ادخل الملف المراد تحميله" : " ادخل الصورة المراد تحميلها"}
               </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} />

            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان   "
              required
            />
      </Modal>
    </>
      
      
  );
};


export default connect(null, {
  addFile,
  setAlert,
})(AddFile);
