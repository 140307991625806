import {
  GET_TOPICS_BY_COURSE,
  ADD_TOPIC,
  UPDATE_TOPIC,
  DELETE_TOPIC,
  ERROR_TOPIC,
  FILTER_TOPICS,
  GET_OLDTOPICS
} from "../actions/types";

const inialState = {
  topics: [],
  topic: null,
  filtered: [],
  oldtopics:[],
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TOPICS_BY_COURSE:
      return {
        ...state,
        topics: payload,
        loading: false,
      };
    case FILTER_TOPICS:
      return {
        ...state,
        filtered: state.topics.filter((topic) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            topic.title.match(regex) 
          );
        }),
      };
    case ADD_TOPIC:
      return {
        ...state,
        topics: [...state.topics, payload],
        loading: false,
      };
    case UPDATE_TOPIC:
      return {
        ...state,
        filtered:
          state.filtered &&
          state.filtered.map((topic) =>
            topic._id === payload._id ? payload : topic
          ),
        topics: state.topics.map((topic) =>
          topic._id === payload._id ? payload : topic
        ),
        loading: false,
      };
    case GET_OLDTOPICS:
      return {
        ...state,
        loading: false,
        oldtopics : payload
      }
    case DELETE_TOPIC:
      return {
        ...state,
        topics: state.topics.filter((topic) => topic._id !== payload),
        loading: false,
      };
    case ERROR_TOPIC:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
