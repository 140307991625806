import axios from "axios";
import {
  FILE_ERROR,
  DELETE_FILE,
  GET_FILES,
  ADD_FILE,
  UPDATE_FILE,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";


export const addExerciseFile = (
  file,
  title,
  discription,
  category,
  exercise
) => async (dispatch) => {
  const formData = new FormData();
  formData.append("lectureFile", file);
  formData.append("title", title);
  formData.append("discription", discription);
  formData.append("category", category);

  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
        }
      },
    };
    const res = await axios.post(`/api/lecture/exercise/${exercise}`, formData, options);
    dispatch({
      type: ADD_FILE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Link

export const addExerciseFileLink = (Lesson ,exerciseID) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/lecture/exercise/${exerciseID}/videolink`,
      Lesson,
      config
    );
    dispatch({
      type: ADD_FILE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Get All Files Subject
export const getExercisefiles = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lecture/exercise/${id}`);
    dispatch({
      type: GET_FILES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};








// Add FILE
export const addFile = (
  file,
  title,
  discription,
  category,
  lesson
) => async (dispatch) => {
  const formData = new FormData();
  formData.append("lectureFile", file);
  formData.append("title", title);
  formData.append("discription", discription);
  formData.append("category", category);

  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
        }
      },
    };
    const res = await axios.post(`/api/lecture/${lesson}`, formData, options);
    dispatch({
      type: ADD_FILE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Link

export const addFileLink = (Lesson ,lessonID) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/lecture/${lessonID}/videolink`,
      Lesson,
      config
    );
    dispatch({
      type: ADD_FILE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Get All Files Subject
export const getfiles = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lecture/${id}`);
    dispatch({
      type: GET_FILES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Update Subject
export const updateLecture = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      `/api/lecture/${formData.id}`,
      formData,
      config
    );

    console.log(res.data);
    dispatch({
      type: UPDATE_FILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete Lecture
export const deleteLecture = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/lecture/${id}`);

    dispatch({
      type: DELETE_FILE,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
