import React, { useState,useEffect } from 'react';
import FormInput from "../../form-input-exam/form-input.component";
import "./Exams.style.scss";
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { addTopic } from "../../../actions/Topics.action";
import {DeleteOutlined ,MinusCircleOutlined ,PlusCircleOutlined ,CheckOutlined} from "@ant-design/icons"
const AddTopics = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dispatch = useDispatch()

  const correntCourse =  useSelector(state => state.courses.course)
  const [Topic, setTopic] = useState({
    course: correntCourse && correntCourse._id,
    Topics: [],
  });

  const [Topics, setTopics] = useState([
    {
      topic: "",
      Lessons: [{ lesson: "" }],
    },
  ]);


  const handleTopicsChange = (idx) => (evt) =>
  setTopics(
    Topics.map((Q, sidx) =>
        idx !== sidx ? Q : { ...Q, topic: evt.target.value }
      )
    );

  const handleLessonChange = (qidx, ansidx) => (evt) => {
    setTopics(
      Topics.map((Q, sidx) =>
        qidx !== sidx
          ? Q
          : {
              ...Q,
              Lessons: Q.Lessons.map((A, aidx) =>
                ansidx !== aidx ? A : {...A,  lesson: evt.target.value }
              ),
            }
      )
    );
  };

  const handleAddToppic = () => {
    setTopics([
      ...Topics,
      { topic: "",  Lessons: [{ lesson: "" }] },
    ]);
    console.log(Topics);
  };


  const handleAddLesson = (qidx) => () =>
    setTopics(
      Topics.map((Q, sidx) =>
        qidx !== sidx ? Q : { ...Q, Lessons: [...Q.Lessons, { lesson: "" }] }
      )
    );

  const handleRemoveTopic = (qidx) => () => {
    setTopics(Topics.filter((s, sidx) => qidx !== sidx));
    setTopic({ ...Topic, Topics: Topics });
  };
  const handleRemoveLesson = (qidx, ansidx) => () => {
    setTopics(
      Topics.map((Q, sidx) =>
        qidx !== sidx
          ? Q
          : {
              ...Q,
              Lessons: Q.Lessons.filter((A, aidx) => ansidx !== aidx),
            }
      )
    );
  };
  useEffect(() => {
    setTopic({ ...Topic, Topics: Topics });
    // eslint-disable-next-line
  }, [Topics]);

  const onSubmit = () => {

    if (Topic.course !== "" && Topic.Topics.length >  0) {
     dispatch(addTopic(Topic));
      setAlert("تمت اضافةالامتحان بنجاح", "success");
      setTopics([
        {
          topic: "",
          Lessons: [{ lesson: "" }],
        },
      ]);
      setTopic({
        course:correntCourse._id,
        Topics: [],
      });
      setIsModalVisible(false)
    } else {
        setAlert("ارجو ادخال عنوان الامتحان  و بيانات عن الامتحان", "danger");
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>  <PlusCircleOutlined /> </a>

      <Modal title="إضافة الدروس " visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          حفط <CheckOutlined />
        </Button>,
      ]}
      >
      
        
        {Topics &&
          Topics !== [] &&
          Topics.map((Topic, qidx) => (
            <>
              <div className="card">
                <span className="flex">
                  <span className="FormInput">
                    <FormInput
                      type="text"
                      handleChange={handleTopicsChange(qidx)}
                      value={Topic.topic}
                      label="ادخل عنوان الموضوع "
                    />
                  </span>
                  {/*  eslint-disable-next-line */}
                  <a
                    className="center-align text-danger btn-custom exam-link "
                    onClick={handleRemoveTopic(qidx)}
                  >
                    <DeleteOutlined />
                  </a>
                </span>
                <ol>
                
                  {Topic.Lessons &&
                    Topic.Lessons.map((Lesson, ansidx) => (
                    <span className="flex">
                      <span className="FormInput">
                          <FormInput
                            type="text"
                            handleChange={handleLessonChange(qidx, ansidx)}
                            value={Lesson.lesson}
                            label={Lesson.lesson === "" && "عنوان الدرس "}
                          />
                        </span>
                        {/*  eslint-disable-next-line */}
                        <a
                          className="center-align text-danger btn-custom exam-link "
                          onClick={handleRemoveLesson(qidx, ansidx)}
                        >
                          <MinusCircleOutlined />
                        </a>
                    </span>
                    ))}
              <div >
                  {/*  eslint-disable-next-line */}
                  <a
                    type="button"
                    onClick={handleAddLesson(qidx)}
                    className="btn-custom center-align pr-5"
                  >
                    <PlusCircleOutlined /> اضافة درس
                  </a>
                  </div>
                </ol>
              </div>
            </>
          ))}
          <div className="text-center">
            {/*  eslint-disable-next-line */}
            <a onClick={handleAddToppic}> <PlusCircleOutlined /> </a>
          </div>
    
      </Modal>
    </>
  );
};

export default AddTopics