import React ,{useEffect} from 'react'
import {Empty} from  "antd"
import {getAdvs} from "../../actions/advs.action"
import {useDispatch ,useSelector} from "react-redux"

const Advs_mainPage = ({school ,year}) => {
    const dispatch = useDispatch()

    useEffect(() => {
       dispatch(getAdvs( school._id ,year._id))
       }, [])
    const { advs} = useSelector(state => state.advs)
  
    const contentStyle = {
        width: "70vw",
        color:"black",
        textAlign: "rigth",
        margin: "3rem auto",
      };
    return (
        <>
         {advs && advs.length > 0 ? 
         < >
         <h1 className="text-center"> لوحة الاعلانات </h1>
         {advs.map(adv =>
          <div style={contentStyle} key={adv._id}>
            <div 
            style={{background : `url(${adv &&adv.image}) center`,  height: "30vh",  borderRadius : "5px",marginBottom : "1rem"

          }}  
            />
            <h1 className="text-center" >{adv.title}</h1>
            <p dangerouslySetInnerHTML={{__html :adv.discription}}></p>
          </div>
           )}
         </>:
        <Empty />
         }   
        </>
    )
}

export default Advs_mainPage
