import React from 'react'
import {Avatar ,Image} from "antd"
import DeleteHomeWork from "../../components/modals/HomeWork/DeleteHomeWork" 

const HomeWorksItems = ({homework}) => {
const {_id,student,course ,images , file , answers} =homework
      
    return (
      <tr>
      
      <td className="right-align">{student.name}</td>
      <td className="right-align">{course && course.courseName}</td>

      <td className="right-align">
      
        {images && images.map(image =>
           <Avatar shape="square"
           src={<Image src={image} />}
          />
          )}
          {file &&  
          // eslint-disable-next-line
          <a href={file} target="_blank" > تحميل الملف</a>}
          {answers &&  <p dangerouslySetInnerHTML={{__html : answers}}></p>}
      </td>
      <td className="right-align">
      <DeleteHomeWork  id={_id}/>
      </td>
    </tr>
    )

}

export default HomeWorksItems
