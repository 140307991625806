import React, {  useState  } from "react";
import { Modal, Button ,Select,DatePicker } from 'antd';
import FormInput from "../../form-input/form-input.component";
import {  useSelector , connect } from "react-redux";
import { addExercise } from "../../../actions/Exercise.actions";
import { setAlert } from "../../../actions/alert";
import {PlusCircleOutlined} from "@ant-design/icons"


const {Option} = Select
const AddClass = ({ addExercise, setAlert ,courseID  }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    setExercise({ ...Exercise, deadline : date  })
  }
  const [Exercise, setExercise] = useState({
    title :"",
    lesson  :"",
    deadline : "",
    course  :courseID, 
  });
  
  
  const {topics} = useSelector(state => state.topics)

  const { title , lesson , course ,deadline} = Exercise;


  const onSubmit = async (e) => {
    e.preventDefault();
    if (lesson === "" ) {
      setAlert("ارجو تعبئة كل الحقول     ", "danger");
    } else {
      console.log(Exercise)
      addExercise(title , lesson , course ,deadline);
      setExercise({
        title :"",
    lesson  :"",
    deadline :"",
    course  :courseID,
      });
      setAlert("تمت إضافة التمرين بنجاح", "success");
      setIsModalVisible(false);    }
  };
  return (
    <>
       {/* eslint-disable-next-line */}
       <a onClick={showModal}>  <PlusCircleOutlined /> </a>
      <Modal title="تكوين تمرين" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          إضافة
        </Button>,
      ]}
      >

            <FormInput
              name="title"
              type="text"
              handleChange={(e) =>setExercise({ ...Exercise, title : e.target.value   })} 
              value={title}
              label="عنوان لتمرين"
              required
            />
            <h4 className="my-1">إختر الوحدة</h4>
            <Select 
                style={{ width: "100%" }} 
                onChange={(e) =>setExercise({ ...Exercise, topic : e   })}   
                name="course" 
                >
                {topics && topics.map(topic =>
                <Option value={topic._id}>{topic.title}</Option>)}
              </Select>

              { Exercise && Exercise.topic && Exercise.topic.length > 0 && 
              <>

              <h4 className="my-1">إختر الدرس</h4>
              <Select 
                style={{ width: "100%" }} 
                onChange={(e) =>setExercise({ ...Exercise, lesson : e   })}   
                name="course" 
                >
                {topics && topics.map(topic =>
                <>
                {topic._id === Exercise.topic &&
                    <>
                      {topic.Lessons.map(lesson =>
                      <Option value={lesson._id}>{lesson.title}</Option>)}
                    </>
                }
                </>
                )}
              </Select>
              <h4 className="my-1"> اخر موعد لتسليم</h4>
              <DatePicker onChange={onChangeDate} />

              </>
              }
      </Modal>
    </>
  );
};

export default connect(null, {
  addExercise,
  setAlert,
})(AddClass);
