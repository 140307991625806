import React, {  useState } from "react";
import { connect } from "react-redux";
import { ResetPassword } from "../../../actions/Members.actions";
import { setAlert } from "../../../actions/alert";
import { Modal, message ,Button} from 'antd';


const DeleteMember = ({ ResetPassword, id, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const Reset = () => {
    ResetPassword(id);
    message.success("تم اعادة ضبط كلمة المرور");
    setIsModalVisible(false);
  };
  return (

<>{/*  eslint-disable-next-line */}
      <a  onClick={showModal}>
      اعادة ضبط كلمة المرور
      </a>
      <Modal title="تأكيد الطلب " visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={Reset}>
              نعم
            </Button>,
          ]}>
        <p>هل انت متأكد من اعادة ضبط كلمة المرور؟</p>

      </Modal>
    </>
      
     
  );
};

export default connect(null, {
  ResetPassword,
  setAlert,
})(DeleteMember);
